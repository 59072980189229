import React, { useEffect, useState } from "react";
import "./style.css";
import axios from "../../utils/axios";
import { useNavigate } from "react-router-dom";
import Overlay from "../../component/overlay";
const ClinetJet = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <section className="clientjet">
        <div className="cjheading">
          <h2>Client Jets</h2>
        </div>
        <div className="cjbody">
          {/* <div className="dropdown cursor-pointer flex justify-end items-center">
            <h4 className="me-4 text-white">Sort by :</h4>
            <select name="cars" id="cars">
              <option value="Date Published">Date Published</option>
              <option value="Relevance">Relevance</option>
              <option value="Price: Low to High">Price: Low to High</option>
              <option value="Price: High to Low">Price: High to Low</option>
              <option value="Distance">Distance</option>
            </select>
          </div> */}
          <List />
          <div className="button1 mt-6 text-center">
            {/* <button>load more</button> */}
          </div>
        </div>
      </section>
    </>
  );
};

export default ClinetJet;

const List = () => {
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const [jetData, setJetData] = useState();

  const handledata = async () => {
    setIsLoading(true);
    try {
      const data = await axios.get("/getLiveJets");
      setJetData(data?.data?.data);
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      console.log(err);
    }
  };

  useEffect(() => {
    handledata();
  }, []);

  return (
    <>
      <div className="flex flex-wrap sm:my-10 items">
        {jetData?.map((item) => {
          return (
            <>
              <div
                className="lg:basis-1/3 sm:basis-1/2 mt-4 sm:p-8 cont cursor-pointer"
                onClick={() =>
                  navigate(`/client-jet-details/${item?.id}`, {
                    state: { item },
                  })
                }
              >
                <div className="contbody">
                  <div>
                    <img src={item?.images[0]} alt="" />
                  </div>
                  <div className="info px-4 py-4">
                    <h3 className="pb-3">{item?.aircraft_name}</h3>
                    <p>{item.range} NM</p>
                    <div className="flex justify-between pt-6">
                      <div>
                        {/* <p>France</p> */}
                        {/* <p>{item.country}</p> */}
                      </div>
                      <div>
                        <DateFormate date={item?.createdAt} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          );
        })}
      </div>
      {isLoading && <Overlay />}
    </>
  );
};

const DateFormate = (props) => {
  const dateString = props?.date;
  const formattedDate = new Intl.DateTimeFormat("en-IN", {
    year: "numeric",
    month: "short",
    day: "numeric",
  }).format(new Date(dateString));
  return (
    <div>
      <p>{formattedDate}</p>
    </div>
  );
};
