import React, { useState } from "react";
import { Formik, Form, ErrorMessage } from "formik";
import PhoneInput, { parsePhoneNumber } from "react-phone-number-input";
import "react-phone-number-input/style.css";
import { contactus } from "../../utils/validation";
import Overlay from "../overlay";
import "./style.css";
import cross from "../../assets/images/cross.svg";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "../../utils/axios";

function Contactus(props) {
  // const navigate = useNavigate();
  const [show, setShow] = useState(false);

  const handleclick = () => {
    // navigate("/");
    setShow(!show);
  };

  return (
    <div className="enquiry">
      <ContactusBody handleclick={handleclick} show={show} />
    </div>
  );
}

export default Contactus;

const ContactusBody = ({ handleclick, show }) => {
  const navigate = useNavigate();

  return (
    <>
      <ToastContainer />
      <div className={` Contactusform`}>
        <div className="head">
          <img
            src={cross}
            className="cursor-pointer"
            alt="Close"
            onClick={() => navigate(-1)}
          />
        </div>
        <ContactusForm handleclick={handleclick} />
      </div>
    </>
  );
};

const ContactusForm = ({ handleclick }) => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [userDetails, setUserDetails] = useState({
    firstName: "",
    lastName: "",
    mobileNumber: "",
    email: "",
    comments: "",
  });

  const handlesubmit = async (values) => {
    setIsLoading(true);

    try {
      const callingCode = parsePhoneNumber(values.mobileNumber);
      const countrycodeis = callingCode.countryCallingCode;
      const newphoneNumber = values.mobileNumber
        .replace(countrycodeis, "")
        .replace(/^(\+)+/, "")
        .trim();
      const formValues = {
        first_name: values.firstName,
        last_name: values.lastName,
        email: values.email,
        phone_number: `${newphoneNumber}`,
        country_code: `+${countrycodeis}`,
        description: values.comments,
      };

      const { data } = await axios.post("/contactUs", formValues);

      // console.log(data.message);

      setUserDetails({
        firstName: "",
        lastName: "",
        email: "",
        mobileNumber: "",
        comments: "",
      });
      setIsLoading(false);
      // navigate("/otp", { state: formValues.email });

      if (data.message === "OTP Sent, Please verify.") {
        navigate("/otp", { state: formValues.email });
        toast.success("Otp Sent Successfully");
      } else {
        navigate("/thanku");
        toast.success("Form Submit Successfully");
      }

      toast.success("Your form has been submitted successfully");
    } catch (error) {
      setIsLoading(false);
      console.error("Error submitting form:", error);
    }
  };

  return (
    <>
      <div className="formbody">
        <div className="headstext">
          <h5 className="text-center">General Inquiry</h5>
          <p>We're here lets connect</p>
        </div>
        <div>
          <Formik
            enableReinitialize
            initialValues={userDetails}
            validate={(values) => contactus(values)}
            validateOnChange
            onSubmit={(values) => {
              handlesubmit(values);
            }}
          >
            {(formikBag) => (
              <Form>
                {/* {console.log(formikBag)} */}

                <div className="d-flex">
                  <div className="sm:flex">
                    <div className="basis-1/2 px-4 mb-5">
                      <div className="form-field">
                        <input
                          readOnly={false}
                          type="text"
                          name="firstName"
                          className="text-capitalize text-white"
                          placeholder="First Name*"
                          value={formikBag.values.firstName}
                          onChange={(e) => {
                            formikBag.setFieldValue(
                              "firstName",
                              e.target.value
                            );
                          }}
                        />
                      </div>
                      <ErrorMessage
                        name="firstName"
                        component="div"
                        className="errormessage"
                      />
                    </div>
                    <div className="basis-1/2 px-4 mb-5">
                      <div className="form-field">
                        <input
                          type="text"
                          name="lastName"
                          className="text-capitalize text-white"
                          placeholder="Last Name*"
                          value={formikBag.values.lastName}
                          onChange={(e) => {
                            formikBag.setFieldValue("lastName", e.target.value);
                          }}
                        />
                      </div>
                      <ErrorMessage
                        name="lastName"
                        component="div"
                        className="errormessage"
                      />
                    </div>
                  </div>
                </div>
                <div className="px-4 mb-5">
                  <div className="form-field flex items-end">
                    <textarea
                      name="comments"
                      className="text-white"
                      placeholder="Specify Your Inquiry*"
                      value={formikBag.values.comments}
                      onChange={(e) => {
                        const inputValue = e.target.value;
                        if (inputValue.length <= 250) {
                          formikBag.setFieldValue("comments", inputValue);
                        }
                      }}
                    ></textarea>
                    <div className="text-white ps-4">
                      {formikBag.values.comments.length}/250
                    </div>
                  </div>
                  <ErrorMessage
                    name="comments"
                    component="div"
                    className="errormessage"
                  />
                </div>
                <div className="sm:flex">
                  <div className="basis-1/2 px-4 mb-5">
                    <div className="form-field">
                      <input
                        className="text-white"
                        type="email"
                        name="email"
                        placeholder="Email Address*"
                        value={formikBag.values.email}
                        onChange={(e) => {
                          formikBag.setFieldValue("email", e.target.value);
                        }}
                      />
                    </div>
                    <ErrorMessage
                      name="email"
                      component="div"
                      className="errormessage"
                    />
                  </div>
                  <div className=" basis-1/2 px-4 mb-5">
                    <div className="form-field">
                      <PhoneInput
                        autoComplete="on"
                        defaultCountry="US"
                        international
                        name="mobileNumber"
                        className="w-100"
                        placeholder="Enter phone number"
                        value={formikBag?.values?.mobileNumber}
                        onChange={(value) => {
                          formikBag.setFieldValue("mobileNumber", value);
                        }}
                        error={
                          formikBag.touched.mobileNumber &&
                          formikBag.errors.mobileNumber
                            ? formikBag.errors.mobileNumber
                            : null
                        }
                      />
                    </div>
                    <p className="errormessage">
                      {formikBag.errors.mobileNumber}
                    </p>
                  </div>
                </div>

                <div className="text-center button1 mt-10">
                  <button type="submit">send request</button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
      {isLoading && <Overlay />}
    </>
  );
};
