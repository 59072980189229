import "./sidebar.css";
import blacklogo from "../../assets/images/blacklogo.svg";
import closebtn from "../../assets/images/icon/closebtn.svg";
import fb from "../../assets/images/icon/fb.svg";
import insta from "../../assets/images/icon/insta.svg";
import twitter from "../../assets/images/icon/twitter.svg";
import linkdin from "../../assets/images/icon/linkdin.svg";
import arrow from "../../assets/images/icon/Arrow.svg";
import { useNavigate, Link } from "react-router-dom";
import { useEffect, useState } from "react";
// import axios from "../../utils/axios";
import axios from "../../utils/axios";
const Sidebar = ({ x, handleClose }) => {
  const navigate = useNavigate();
  const [tourShow, setTourShow] = useState(false);

  const [jetshow, setJetShow] = useState(false);
  const handledata = async () => {
    try {
      const data = await axios.get("/getLiveJets");
      const tourdata = await axios.get("/getTours");
      // console.log(tourdata?.data?.data, "tour data");
      if (data?.data?.data == null) {
        setJetShow(false);
      } else {
        setJetShow(true);
      }

      if (tourdata?.data?.data == null) {
        setTourShow(false);
      } else {
        setTourShow(true);
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    handledata();
  }, []);

  return (
    <>
      <section
        className="mainsidebar"
        style={{ transform: `translateX(${x}%)` }}
        onClick={() => handleClose()}
      >
        <section className="sidebar">
          <div className="flex items-center justify-between head">
            <div className="flex items-center" onClick={() => navigate("/")}>
              <div>
                <img src={blacklogo} alt="" />
              </div>
              <div className="ps-10">
                <h5>Seven air Aviation</h5>
              </div>
            </div>
            <div>
              <img
                src={closebtn}
                alt=""
                className="cursor-pointer"
                onClick={() => handleClose()}
              />
            </div>
          </div>
          <div className="navs">
            <ul>
              <li
                onClick={() => {
                  navigate("/");
                  handleClose();
                }}
              >
                home <img src={arrow} alt="img" />
              </li>
              <li
                onClick={() => {
                  navigate("/buy");
                  handleClose();
                }}
              >
                buy <img src={arrow} alt="img" />
              </li>

              <li onClick={() => navigate("/sell")}>
                sell <img src={arrow} alt="img" />
              </li>

              {tourShow ? (
                <li onClick={() => navigate("/tour")}>
                  tour <img src={arrow} alt="img" />
                </li>
              ) : null}

              {jetshow ? (
                <li onClick={() => navigate("/client-jet")}>
                  client jets <img src={arrow} alt="img" />
                </li>
              ) : null}

              <li onClick={() => navigate("/AboutUs")}>
                about us <img src={arrow} alt="img" />
              </li>
              <li onClick={() => navigate("/inquiry")}>
                contact us <img src={arrow} alt="img" />
              </li>
            </ul>
          </div>
          <div className="social-icon">
            <ul className="flex items-center justify-evenly">
              <li>
                <Link to="https://twitter.com/Teamsevenair" target="_blank">
                  <img src={twitter} alt="" className="cursor-pointer" />
                </Link>
              </li>
              <li>
                <Link
                  to="https://www.instagram.com/sevenairaviation/"
                  target="_blank"
                >
                  <img src={insta} alt="" className="cursor-pointer" />
                </Link>
              </li>
              <li>
                <Link
                  to="https://www.facebook.com/sevenairaviation"
                  target="_blank"
                >
                  <img src={fb} alt="" className="cursor-pointer" />
                </Link>
              </li>
              <li>
                <img src={linkdin} alt="" className="cursor-pointer" />
              </li>
            </ul>
          </div>
        </section>
      </section>
    </>
  );
};

export default Sidebar;
