import React, { useEffect, useState } from "react";
import "./style.css";

import twitter from "../../assets/images/aboutus/twitter.svg";
import insta from "../../assets/images/aboutus/insta.svg";
import fb from "../../assets/images/aboutus/fb.png";
import linkdin from "../../assets/images/aboutus/linkdin.svg";
import axios from "../../utils/axios";
import { Link } from "react-router-dom";

function AboutUs() {
  const [aboutData, setAboutData] = useState();
  const handledata = async () => {
    try {
      const data = await axios.get("/getAboutUs");
      setAboutData(data?.data?.data);
    } catch (e) {}
  };

  useEffect(() => {
    handledata();
    window.scrollTo(0, 0);
  }, []);

  const sectionA = aboutData?.[0]?.section_a;
  const sectionB = aboutData?.[0]?.section_b;
  const sectionC = aboutData?.[0]?.section_c;

  return (
    <>
      <secton className="aboutuspage">
        <div className="aboutheading">
          <h2>About Us</h2>
        </div>

        <div className="aboutbody">
          <div className="md:flex items-center">
            <div className="basis-2/5 flex pb-6 justify-center">
              <img src={sectionA?.a_image} alt="" />
            </div>
            <div className="basis-3/5 ps-5">
              <div
                dangerouslySetInnerHTML={{
                  __html: sectionA?.a_content?.a_paragraph,
                }}
              ></div>
            </div>
          </div>
          <div className="heading my-10">
            <h2>{sectionB?.b_content?.b_heading}</h2>
          </div>
          <div className="md:flex items-center">
            <div className="basis-3/5">
              <div
                dangerouslySetInnerHTML={{
                  __html: sectionB?.b_content?.b_paragraph,
                }}
              ></div>
            </div>
            <div className="basis-2/5 flex justify-center p-5">
              <img src={sectionB?.b_image} alt="" />
            </div>
          </div>
          <div className="heading my-10">
            <h2>Our Competitive Advantage</h2>
          </div>
          <div className="md:flex items-center">
            <div className="basis-2/5 flex justify-center p-5">
              <img src={sectionC?.c_image} alt="" />
            </div>
            <div className="basis-3/5">
              <div
                dangerouslySetInnerHTML={{
                  __html: sectionC?.c_content?.c_paragraph,
                }}
              ></div>
            </div>
          </div>
          <div className="client">
            <div className="heading my-10">
              <h2 className="pb-6">Meet the Team</h2>
              <p>
                Our team is composed of experienced sales professionals who are
                passionate about connecting with customers and providing the
                best possible solutions. Our expertise lies in understanding
                customer needs and delivering tailored services that help our
                clients succeed. We strive to create long-term relationships
                with our customers to ensure their goals are met with higher
                standards.
              </p>
            </div>
            <Client />
          </div>
        </div>
      </secton>
    </>
  );
}

export default AboutUs;

const Client = () => {
  const [memberData, setMemberData] = useState();
  const handledata = async () => {
    try {
      const data = await axios.get("/getClient");
      setMemberData(data?.data?.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    handledata();
  }, []);

  return (
    <>
      <div className="flex flex-wrap justify-center clientcont">
        {memberData?.map((item) => {
          return (
            <>
              <div className="lg:basis-1/4 md:basis-1/3  sm:basis-1/2 cont">
                <div>
                  <img src={item?.image} alt="" className="clientimg w-full" />
                </div>
                <div className="info text-center px-4">
                  <div>
                    <h4>{item?.client_name}</h4>
                    <h6>{item?.client_post}</h6>
                    <div className="social cursor-pointer flex justify-evenly mt-3">
                      <div>
                        <Link to="https://twitter.com/Teamsevenair">
                          <img src={twitter} alt="" />
                        </Link>
                      </div>
                      <div>
                        <Link to="https://www.instagram.com/sevenairaviation/">
                          <img src={insta} alt="" />
                        </Link>
                      </div>
                      <div>
                        <Link to="https://www.facebook.com/sevenairaviation">
                          <img src={fb} alt="" />
                        </Link>
                      </div>
                      <div>
                        <img src={linkdin} alt="" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          );
        })}
      </div>
    </>
  );
};
