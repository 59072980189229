import React, { useEffect, useState, useRef } from "react";
import "./banner.css";

const isSafari = () => {
  const ua = navigator.userAgent.toLowerCase();
  return ua.indexOf("safari") > -1 && ua.indexOf("chrome") < 0;
};

const Banner = () => {
  const videoParentRef = useRef();
  const [shouldUseImage, setShouldUseImage] = useState(false);

  useEffect(() => {
    if (isSafari() && videoParentRef.current) {
      const player = videoParentRef.current.children[0];

      if (player) {
        player.controls = false;
        player.playsinline = true;
        player.muted = true;
        player.setAttribute("muted", "");
        player.autoplay = true;

        setTimeout(() => {
          const promise = player.play();
          if (promise.then) {
            promise
              .then(() => {})
              .catch(() => {
                videoParentRef.current.style.display = "none";
                setShouldUseImage(true);
              });
          }
        }, 0);
      }
    }
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <section className="banner">
        <div>
          {shouldUseImage ? (
            <img
              src="https://sevenairimage.s3.us-east-2.amazonaws.com/video/1704445689360.mp4"
              alt="Muted Video"
            />
          ) : (
            <div
              ref={videoParentRef}
              dangerouslySetInnerHTML={{
                __html: `
                <video
                  loop
                  muted
                  autoplay
                  playsinline
                  preload="metadata"
                >
                <source src="https://sevenairimage.s3.us-east-2.amazonaws.com/video/1704445689360.mp4" type="video/mp4" />
                </video>`,
              }}
            />
          )}

          <div className="text-section">
            <h2 className="text-center">Private Jets </h2>
            <h1 className="text-center">Acquisition made</h1>
            <h3>Smooth!</h3>
          </div>
          <div className="scroll-down">
            <div className="point"></div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Banner;
