import React, { useEffect, useState, useRef } from "react";
import "./style.css";
import arrow from "../../assets/images/tour/arrow.svg";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import flight from "../../assets/images/tour/icon/flight.svg";
import hotel from "../../assets/images/tour/icon/hotel.svg";
import block from "../../assets/images/tour/icon/block.svg";
import car from "../../assets/images/tour/icon/car.svg";
import btnarrow from "../../assets/images/tour/icon/btn.svg";
import { useNavigate } from "react-router-dom";
import TourInquiryForm from "../../component/TourInquiry/TourInquiry";
import Overlay from "../../component/overlay";

import axios from "../../utils/axios";
const isSafari = () => {
  const ua = navigator.userAgent.toLowerCase();
  return ua.indexOf("safari") > -1 && ua.indexOf("chrome") < 0;
};

const Tour = () => {
  const videoParentRef = useRef();
  const [shouldUseImage, setShouldUseImage] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
    if (isSafari() && videoParentRef.current) {
      const player = videoParentRef.current.children[0];

      if (player) {
        player.controls = false;
        player.playsinline = true;
        player.muted = true;
        player.setAttribute("muted", "");
        player.autoplay = true;

        setTimeout(() => {
          const promise = player.play();
          if (promise.then) {
            promise
              .then(() => {})
              .catch(() => {
                videoParentRef.current.style.display = "none";
                setShouldUseImage(true);
              });
          }
        }, 0);
      }
    }
  }, []);

  return (
    <>
      <section className="tour">
        <div className="tourbanner">
          <div>
            {shouldUseImage ? (
              <img
                src="https://sevenairimage.s3.us-east-2.amazonaws.com/video/1705382318682.mp4"
                alt="Muted Video"
              />
            ) : (
              <div
                ref={videoParentRef}
                dangerouslySetInnerHTML={{
                  __html: `
                <video
                  loop
                  muted
                  autoplay
                  playsinline
                  preload="metadata"
                >
                <source src="https://sevenairimage.s3.us-east-2.amazonaws.com/video/1705382318682.mp4" type="video/mp4" />
                </video>`,
                }}
              />
            )}

            <div className="text-section">
              <h1 className="text-center">Travel with</h1>
              <div className="relative flex">
                <h3>Confort</h3>
                <div>
                  <img src={arrow} alt="" className="arrowt" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="tourbody">
          <div className="sm:flex upcom justify-between items-center">
            <h3>Upcoming Tours</h3>
            <div className="line sm:block hidden"></div>
            <p>Tour Packages</p>
          </div>
          <div className="tourlist flex justify-center">
            <Package />
          </div>
          {/* <Countrylist /> */}
          <TourInquiryForm title="Make an Inquiry" />
        </div>
      </section>
    </>
  );
};

export default Tour;

const Package = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [packageData, setPackageData] = useState();

  const fetchData = async () => {
    try {
      const data = await axios.get("/getTours");
      setPackageData(data?.data?.data);
    } catch (e) {
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const settings = {
    autoplay: true,
    dots: false,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    speed: 5000,
    autoplaySpeed: 100,
    pauseOnHover: true,
  };

  // console.log(packageData);
  return (
    <>
      <div className="container  ">
        {packageData?.map((item) => {
          return (
            <>
              <div className="flex items-center lg:flex-row flex-col justify-center mt-16">
                <div className="basis-2/5">
                  <div>
                    <Slider {...settings} className="slider1">
                      {item?.images?.map((imageUrl, index) => (
                        <div key={index} className="h-full">
                          <img src={imageUrl} alt="img" />
                        </div>
                      ))}
                    </Slider>
                  </div>
                </div>
                <div className="basis-3/5 details p-4">
                  <h3>
                    {item?.jet_type} , {item.tour_title}
                  </h3>
                  <div className="flex iconlist">
                    <img src={flight} alt="1" />
                    <img src={hotel} alt="1" />
                    <img src={block} alt="1" />
                    <img src={car} alt="1" />
                  </div>

                  <p>{item.short_description}</p>
                  <div className="btn1">
                    <button
                      className="flex items-center"
                      onClick={() =>
                        navigate(`/Tour-details/${item?.id}`, { state: item })
                      }
                    >
                      View Details{" "}
                      <img src={btnarrow} className="ps-8" alt="" />
                    </button>
                  </div>
                </div>
              </div>
            </>
          );
        })}
      </div>
      {isLoading && <Overlay />}
    </>
  );
};

// const Countrylist = () => {
//   const list = [
//     {
//       img: require("../../assets/images/tour/india.png"),
//       name: "India",
//     },
//     {
//       img: require("../../assets/images/tour/paris.png"),
//       name: "Paris",
//     },
//     {
//       img: require("../../assets/images/tour/bali.png"),
//       name: "bali",
//     },
//     {
//       img: require("../../assets/images/tour/dubai.png"),
//       name: "dubai",
//     },
//     {
//       img: require("../../assets/images/tour/india.png"),
//       name: "India",
//     },
//     {
//       img: require("../../assets/images/tour/paris.png"),
//       name: "Paris",
//     },
//     {
//       img: require("../../assets/images/tour/bali.png"),
//       name: "bali",
//     },
//     {
//       img: require("../../assets/images/tour/dubai.png"),
//       name: "dubai",
//     },
//   ];
//   const settings = {
//     autoplay: false,
//     dots: false,
//     infinite: true,
//     slidesToShow: 4,
//     slidesToScroll: 1,
//     arrows: true,
//     speed: 500,
//     autoplaySpeed: 100,
//     pauseOnHover: true,
//     responsive: [
//       {
//         breakpoint: 1024,
//         settings: {
//           slidesToShow: 2,
//           slidesToScroll: 1,
//         },
//       },
//       {
//         breakpoint: 600,
//         settings: {
//           slidesToShow: 1,
//           slidesToScroll: 1,
//         },
//       },
//     ],
//   };

//   return (
//     <>
//       <div className="countrylist">
//         <div className="heading">
//           <h2 className="mb-8">weekend getaways</h2>
//           <p>
//             Lorem ipsum dolor sit amet consectetur. Vivamus maecenas odio morbi
//             pellentesque quisque accumsan praesent morbi. Fermentum mattis vitae
//             porta velit mi mattis. Imperdiet sagittis ornare dictumst id lorem.
//           </p>
//         </div>
//         <div className="cont">
//           <Slider {...settings} className="slider2 relative">
//             {list.map((item, index) => (
//               <>
//                 <div
//                   key={index}
//                   className="flex justify-center items-center relative contbody"
//                 >
//                   <div className="wraper">
//                     <div className="relative">
//                       <img src={item.img} alt="img" />
//                       <div className="bgg"></div>
//                       <div className="text-center text">
//                         <h3 className="text-white">{item.name}</h3>
//                       </div>
//                     </div>
//                   </div>
//                 </div>
//               </>
//             ))}
//           </Slider>
//         </div>
//       </div>
//     </>
//   );
// };
