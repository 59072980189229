import React, { useRef, useState, useEffect } from "react";
import "./dealwith.css";
import img1 from "../../assets/images/deal/aviationfuels.png";
import img2 from "../../assets/images/deal/Helicopter.png";
import Cooperatejet from "../../assets/images/deal/Cooperatejet.png";
import privatejetvid from "../../assets/images/deal/privatejetvid.png";
import { useNavigate } from "react-router-dom";

const isSafari = () => {
  const ua = navigator.userAgent.toLowerCase();
  return ua.indexOf("safari") > -1 && ua.indexOf("chrome") < 0;
};

const mainVideo =
  "https://sevenairimage.s3.us-east-2.amazonaws.com/video/1704445825224.mp4";

const Dealwith = () => {
  const videoParentRef = useRef();
  const [shouldUseImage, setShouldUseImage] = useState(false);
  useEffect(() => {
    if (isSafari() && videoParentRef.current) {
      const player = videoParentRef.current.children[0];

      if (player) {
        player.controls = false;
        player.playsinline = true;
        player.muted = true;
        player.setAttribute("muted", "");
        player.autoplay = true;

        setTimeout(() => {
          const promise = player.play();
          if (promise.then) {
            promise
              .then(() => {})
              .catch(() => {
                videoParentRef.current.style.display = "none";
                setShouldUseImage(true);
              });
          }
        }, 0);
      }
    }
  }, []);

  const navigate = useNavigate();
  const videoRef = useRef(null);
  const [isPlaying, setIsPlaying] = useState(false);

  const handleMouseEnter = () => {
    if (videoRef.current && !isPlaying) {
      videoRef.current
        .play()
        .then(() => {
          setIsPlaying(true);
        })
        .catch((error) => {
          console.error("Error playing the video:", error);
        });
    }
  };

  const handleMouseLeave = () => {
    if (videoRef.current && isPlaying) {
      videoRef.current.pause();
      videoRef.current.currentTime = 0;
      setIsPlaying(false);
    }
  };

  const videoRefs = useRef(null);
  const [isPlayings, setIsPlayings] = useState(false);

  const handleMouseEnters = () => {
    if (videoRefs.current && !isPlayings) {
      videoRefs.current
        .play()
        .then(() => {
          setIsPlayings(true);
        })
        .catch((error) => {
          console.error("Error playing the video:", error);
        });
    }
  };

  const handleMouseLeaves = () => {
    if (videoRefs.current && isPlayings) {
      videoRefs.current.pause();
      videoRefs.current.currentTime = 0;
      setIsPlayings(false);
    }
  };

  // console.log(isPlaying);
  // console.log(isPlayings);

  return (
    <>
      <section className="Dealwith">
        <div>
          {shouldUseImage ? (
            <img src={mainVideo} alt="Muted Video" />
          ) : (
            <div
              className="bgvideo"
              ref={videoParentRef}
              dangerouslySetInnerHTML={{
                __html: `
        <video
          loop
          muted
          autoplay
          playsinline
          preload="metadata"
        >
        <source src="${mainVideo}" type="video/mp4" />
        </video>`,
              }}
            />
          )}
          {/* <video autoPlay muted loop className="bgvideo">
            <source
              src="https://sevenairimage.s3.us-east-2.amazonaws.com/video/1704445825224.mp4"
              type="video/mp4"
            />
            Your browser does not sasfupport the video tag.
          </video> */}
          <div className="body-section">
            <div className="heading">
              <h2>we deal with</h2>
            </div>
            <div className="flex flex-row flex-wrap dealcardbody w-full">
              <div
                className="dealcardcont lg:basis-1/4 sm:basis-1/2 mb-6"
                onClick={() => navigate("/helicopter")}
              >
                <div className="dealcard">
                  <img src={img2} alt="" className="hoverimg" />
                </div>
                <div className="name">
                  <h3>Helicopters</h3>
                </div>
              </div>
              <div
                className="dealcardcont lg:basis-1/4 sm:basis-1/2 mb-6"
                onClick={() => navigate("/privatejet")}
              >
                <div className="dealcard">
                  <video
                    muted
                    loop
                    playsinline
                    ref={videoRef}
                    poster={privatejetvid}
                    className="hovervideo"
                    onMouseEnter={handleMouseEnter}
                    onMouseLeave={handleMouseLeave}
                    style={{ WebkitMediaControls: "display(none)" }}
                  >
                    <source
                      src="https://sevenairimage.s3.us-east-2.amazonaws.com/video/1704446147396.mp4"
                      type="video/mp4"
                    />
                    Your browser does not sasfupport the video tag.
                  </video>
                </div>
                <div className="name">
                  <h3>Private Jet</h3>
                </div>
              </div>
              <div
                className="dealcardcont lg:basis-1/4 sm:basis-1/2 mb-6"
                onClick={() => navigate("/commercialjet")}
              >
                <div className="dealcard">
                  <video
                    muted
                    loop
                    ref={videoRefs}
                    poster={Cooperatejet}
                    onMouseEnter={handleMouseEnters}
                    onMouseLeave={handleMouseLeaves}
                    className="hovervideo"
                  >
                    <source
                      src="https://sevenairimage.s3.us-east-2.amazonaws.com/video/1704446990203.mp4"
                      type="video/mp4"
                    />
                    Your browser does not sasfupport the video tag.
                  </video>
                </div>
                <div className="name">
                  <h3>Commercial jet</h3>
                </div>
              </div>
              <div
                className="dealcardcont lg:basis-1/4 sm:basis-1/2 mb-6"
                onClick={() => navigate("/aviation")}
              >
                <div className="dealcard">
                  <img src={img1} alt="" className="hoverimg" />
                </div>
                <div className="name">
                  <h3>aviation fuel</h3>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Dealwith;
