import React, { useEffect } from "react";
import "./style.css";
import { useLocation } from "react-router-dom";
function Tour_img() {
  const location = useLocation();

  const { state } = location;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  console.log(state, "data is");
  return (
    <>
      <section className="tourimages">
        <div className="flex flex-wrap cont justify-center">
          {state?.images?.map((item) => {
            return (
              <div className="basis-1-3 p-4">
                <img src={item} alt="" />
              </div>
            );
          })}
        </div>
      </section>
    </>
  );
}

export default Tour_img;
