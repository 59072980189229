import React from "react";
import "./jets.css";
import Slider from "react-slick";
import { useNavigate } from "react-router-dom";

function Jets() {
  const naviagte = useNavigate();

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const data = [
    {
      img: require("../../assets/images/aircraft/g700.png"),
      title: `Gulfstream G700`,
      range: "7750 NM",
      passengers: 19,
      route: "g700",
    },
    {
      img: require("../../assets/images/aircraft/one.png"),
      title: `2000 Gulfstream G200`,
      range: "7500 NM",
      passengers: 16,
      route: "jetdetails",
    },
    {
      img: require("../../assets/images/aircraft/two.png"),
      title: "2008 Global 5000",
      range: "6500NM",
      passengers: 14,
      route: "jetdetails",
    },
    {
      img: require("../../assets/images/aircraft/three.png"),
      title: "2000 Gulfstream G550",
      range: "6500NM",
      passengers: 14,
      route: "jetdetails",
    },
    {
      img: require("../../assets/images/aircraft/four.png"),
      title: "2001 Global Express",
      range: "7500NM",
      passengers: 16,
      route: "jetdetails",
    },
    {
      img: require("../../assets/images/aircraft/six.png"),
      title: "1997 Challenger 604",
      range: "6500NM",
      passengers: 50,
      route: "jetdetails",
    },
    {
      img: require("../../assets/images/aircraft/seven.png"),
      title: "2020 Phenom 300E",
      range: "7500NM",
      passengers: 8,
      route: "jetdetails",
    },
  ];

  return (
    <section className="jet">
      <div>
        <div className="heading sm:flex justify-center items-center">
          <h2>Best in Jets</h2>
          <div className="line mx-4"></div>
          <p>Private / corporate</p>
        </div>
        <div className="silderjet">
          <Slider {...settings}>
            {data.map((item, index) => {
              return (
                <>
                  <div
                    className="cards cursor-pointer"
                    key={index}
                    onClick={() => naviagte(`/${item.route}`)}
                  >
                    <div className="flex items-center justify-center flex-col">
                      <div>
                        <div>
                          <img src={item.img} alt="" />
                        </div>
                        <div className="ps-8 pt-4">
                          <p className="title">{item.title}</p>
                          <h6>Range-{item.range}</h6>
                          <h6>Passengers-{item.passengers}</h6>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              );
            })}
          </Slider>
        </div>
      </div>
    </section>
  );
}

export default Jets;
