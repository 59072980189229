import React, { useEffect, useState } from "react";
import "./brand.css";
import s1 from "../../assets/images/brand/s1.png";
import s2 from "../../assets/images/brand/s2.svg";
import s3 from "../../assets/images/brand/s3.png";
import s4 from "../../assets/images/brand/s4.png";
import s5 from "../../assets/images/brand/s5.png";
import s6 from "../../assets/images/brand/s6.svg";
import s7 from "../../assets/images/brand/s7.png";
import s8 from "../../assets/images/brand/s8.png";

import prev from "../../assets/images/testonomial/prev.svg";
import next from "../../assets/images/testonomial/next.svg";

import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css/pagination";

// import required modules
import {
  Autoplay,
  Mousewheel,
  Pagination,
  Navigation,
  Keyboard,
} from "swiper/modules";

function Brand() {
  const data = [
    {
      img: s1,
    },
    {
      img: s2,
    },
    {
      img: s3,
    },
    {
      img: s4,
    },
    {
      img: s5,
    },
    {
      img: s6,
    },
    {
      img: s7,
    },
    {
      img: s8,
    },
  ];

  useEffect(() => {
    const scrollers = document.querySelectorAll(".scroller");

    if (!window.matchMedia("(prefers-reduced-motion: reduce)").matches) {
      addAnimation();
    }

    function addAnimation() {
      scrollers.forEach((scroller) => {
        scroller.setAttribute("data-animated", true);

        const scrollerInner = scroller.querySelector(".scroller__inner");

        if (scrollerInner) {
          const scrollerContent = Array.from(scrollerInner.children);

          scrollerContent.forEach((item) => {
            const duplicatedItem = item.cloneNode(true);
            duplicatedItem.setAttribute("aria-hidden", true);
            scrollerInner.appendChild(duplicatedItem);
          });
        }
      });
    }
  }, []);

  const [direction, setDirection] = useState("right");
  const [speed, setSpeed] = useState("slow");

  const changeDirection = (newDirection) => {
    setDirection(newDirection);
    setSpeed("fast");

    if (newDirection === "right") {
      setTimeout(() => {
        setSpeed("slow");
      }, 1000);
    } else {
      setTimeout(() => {
        setDirection("left");
        setSpeed("slow");
      }, 1000);
      setTimeout(() => {
        setDirection("right");
      }, 0);
    }
  };
  return (
    <>
      <section className="brand">
        <div>
          <div className="heading">
            <h2 className="pb-5">Your Favorites, Our Commitment</h2>
            <p>Brands We Present with Pride</p>
          </div>
          <div className="sbody relative">
            <button
              className="contbtn1"
              onClick={() => changeDirection("left")}
            >
              <img src={prev} alt="" />
            </button>
            <button
              className="contbtn2"
              onClick={() => changeDirection("right")}
            >
              <img src={next} alt="" />
            </button>
            <div
              className="scroller"
              data-animated="true"
              data-direction={direction}
              data-speed={speed}
            >
              <div className="scroller__inner ">
                <div className="slide">
                  <img src={s1} alt="" />
                </div>
                <div className="slide">
                  <img src={s2} alt="" />
                </div>
                <div className="slide">
                  <img src={s3} alt="" />
                </div>
                <div className="slide">
                  <img src={s4} alt="" />
                </div>
                <div className="slide">
                  <img src={s5} alt="" />
                </div>
                <div className="slide">
                  <img src={s6} alt="" />
                </div>
                <div className="slide">
                  <img src={s7} alt="" />
                </div>
                <div className="slide">
                  <img src={s8} alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Brand;
