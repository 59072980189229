import React, { useEffect } from "react";
import "./style.css";
import bg from "../../assets/images/jet/bg.png";
import EnquiryForm from "../../component/enquiryform/EnquiryForm";
import leaf from "../../assets/images/jet/leaf.svg";
import seat from "../../assets/images/jet/seat.svg";
import meter from "../../assets/images/jet/meter.svg";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import d1 from "../../assets/images/jet/d1.png";
import d2 from "../../assets/images/jet/d2.png";
import p1 from "../../assets/images/jet/p1.png";
import p2 from "../../assets/images/jet/p2.png";
import p3 from "../../assets/images/jet/p3.png";
import bg2 from "../../assets/images/jet/bg2.png";

export default function JetDetails() {
  useEffect(() => {
    window.scrollTo(0, 0);
  });
  return (
    <>
      <section className="jetdetails">
        <div className="banners">
          <div>
            <div className="bg">
              <img src={bg} alt="" />
            </div>
            <div className="text-section mt-7">
              <div className="flex texthead">
                <span className="span">2000</span>
                <h2>Gulfstream</h2>
              </div>
              <h3>G200</h3>
              <div className="mt-16">
                <EnquiryForm title="get in touch" />
              </div>
            </div>
            <div className="scroll-down">
              <div className="point"></div>
            </div>
          </div>
        </div>
        <div className="best">
          <div className="heading">
            <h2>best comfort in the segment</h2>
          </div>
          <div className="md:flex cont mt-8">
            <div className="basis-1/3 contbody">
              <div className="imgcont">
                <img src={leaf} alt="" />
              </div>
              <p> Your comfort our priority</p>
            </div>
            <div className="basis-1/3 contbody">
              <div className="imgcont">
                <img src={seat} alt="" />
              </div>
              <p>Stay entertained at 45,000 feet</p>
            </div>
            <div className="basis-1/3 contbody">
              <div className="imgcont">
                <img src={meter} alt="" />
              </div>
              <p>equipped with state-of-the-art avionics</p>
            </div>
          </div>
        </div>
        <Sliders />
        <div className="about">
          <div className="lg:flex items-center">
            <div className="basis-1/2">
              <img src={d1} alt="" className="imgss" />
            </div>
            <div className="basis-1/2">
              <h5>
                Lorem ipsum dolor sit amet consectetur. Vel quis commodo quisque
                lectus ac morbi. Sed duis fames imperdiet nibh.
              </h5>
              <p>
                Lorem ipsum dolor sit amet consectetur. Proin ligula
                sollicitudin libero pellentesque imperdiet. Ut sit ut malesuada
                ut laoreet integer cursus. Maecenas sit scelerisque suspendisse
                sapien. Gravida elementum pharetra risus iaculis amet ut lectus.
                Mattis blandit enim viverra sollicitudin. Pulvinar ut enim sed
                quam hendrerit adipiscing nullam elementum. Laoreet nec id sem
                scelerisque. Sociis arcu tellus ullamcorper odio et nunc ac
                morbi. Arcu urna ac odio euismod. At augue varius in proin orci
                lectus tellus. Vitae blandit ut amet lacus adipiscing tempus
                semper sociis ut. Nunc congue hendrerit cursus rhoncus gravida
                arcu quam maecenas. Volutpat at bibendum mi tincidunt dictumst
                ipsum. Urna iaculis nulla purus dis est urna lacinia. Mauris
                augue a dolor morbi velit. Nulla enim consectetur blandit
                consectetur diam facilisis fermentum feugiat amet. Cursus cras
                tellus purus non odio turpis donec. Nunc aliquam viverra fames
                in nunc. Felis vestibulum quis vitae interdum leo.
              </p>
            </div>
          </div>
          <div className="aboutbody">
            <div className="cards">
              <h4>DAZZLING POWER EFFORTLESS PERFORMANCE LIMITLESS comfort</h4>
            </div>
            <EnquiryForm title="make an Inquiry" />
          </div>
        </div>
        <div className="bgimg">
          <img src={bg2} alt="" />
        </div>
        <div className="about">
          <div className="lg:flex items-center">
            <div className="basis-1/2">
              <h5>
                Lorem ipsum dolor sit amet consectetur. Vel quis commodo quisque
                lectus ac morbi. Sed duis fames imperdiet nibh.
              </h5>
              <p>
                Lorem ipsum dolor sit amet consectetur. Proin ligula
                sollicitudin libero pellentesque imperdiet. Ut sit ut malesuada
                ut laoreet integer cursus. Maecenas sit scelerisque suspendisse
                sapien. Gravida elementum pharetra risus iaculis amet ut lectus.
                Mattis blandit enim viverra sollicitudin. Pulvinar ut enim sed
                quam hendrerit adipiscing nullam elementum. Laoreet nec id sem
                scelerisque. Sociis arcu tellus ullamcorper odio et nunc ac
                morbi. Arcu urna ac odio euismod. At augue varius in proin orci
                lectus tellus. Vitae blandit ut amet lacus adipiscing tempus
                semper sociis ut. Nunc congue hendrerit cursus rhoncus gravida
                arcu quam maecenas. Volutpat at bibendum mi tincidunt dictumst
                ipsum. Urna iaculis nulla purus dis est urna lacinia. Mauris
                augue a dolor morbi velit. Nulla enim consectetur blandit
                consectetur diam facilisis fermentum feugiat amet. Cursus cras
                tellus purus non odio turpis donec. Nunc aliquam viverra fames
                in nunc. Felis vestibulum quis vitae interdum leo.
              </p>
            </div>
            <div className="basis-1/2 flex justify-center">
              <img src={d2} alt="" className="imgss" />
            </div>
          </div>
        </div>
        <div className="mcards">
          <div className="md:flex justify-center">
            <div className="img3">
              <img src={p1} alt="" className="m-auto mb-4" />
            </div>
            <div className="img3">
              <img src={p2} alt="" className="m-auto md:mx-10 mb-4" />
            </div>
            <div className="img3">
              <img src={p3} alt="" className="m-auto mb-4" />
            </div>
          </div>

          <EnquiryForm title="make an inquiry" />
        </div>
      </section>
    </>
  );
}

const Sliders = () => {
  const data = [
    {
      img: require("../../assets/images/jet/s1.png"),
    },
    {
      img: require("../../assets/images/jet/s2.png"),
    },
    {
      img: require("../../assets/images/jet/s3.png"),
    },
  ];
  const settings = {
    autoplay: true,
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: "true",
  };
  return (
    <>
      <div className="jetslider">
        <Slider {...settings} className="sldiers">
          {data.map((item, index) => (
            <div key={index} className="cards">
              <div className="">
                <div>
                  <img src={item.img} alt="" className="imgss" />
                </div>
              </div>
            </div>
          ))}
        </Slider>
      </div>
    </>
  );
};
