import React from "react";
import "./style.css";
import logo from "../../assets/images/logo.png";
import x from "../../assets/images/logos/X.svg";
import iat from "../../assets/images/logos/iat.png";
import line from "../../assets/images/logos/line.svg";
import nbaa from "../../assets/images/logos/nbaa.png";

import nav from "../../assets/images/icon/navtoggle.svg";
import { useNavigate } from "react-router-dom";
const Header = ({ handleOpen, inquiry }) => {
  const navigate = useNavigate();
  return (
    <header className="flex">
      <div className="md:basis-1/3 flex justify-start">
        <div className="flex items-center">
          <div>
            <img
              src={logo}
              alt="Logo"
              className="pe-2 logomain"
              onClick={() => navigate("/")}
            />
          </div>
          <div>
            <img src={x} alt="Logo" className="sm:px-2 px-1" />
          </div>
          <div>
            <img src={iat} alt="Logo" className="iat" />
          </div>
          <div>
            <img
              src={line}
              alt="Logo"
              className="lg:px-4 sm:px-2 px-1 logoline"
            />
          </div>
          <div>
            <img src={nbaa} alt="Logo" className="nbaa" />
          </div>
        </div>
      </div>
      <div className="md:basis-1/3 md:flex hidden justify-center">
        <div>
          <h2 className="px-2">Seven Air Aviation</h2>
        </div>
      </div>
      <div className="md:basis-1/3 flex justify-end">
        <div className="flex btn items-center">
          <button className="btn1" onClick={() => inquiry()}>
            Get in touch
          </button>
          <img
            src={nav}
            className="sm:ms-4 ms-2"
            alt="Navigation"
            onClick={handleOpen}
          />
        </div>
      </div>
    </header>
  );
};

export default Header;
