import React, { useEffect } from "react";
import "./style.css";
import bg from "../../assets/images/jet/g700/bg1.jpg";
import bgg700 from "../../assets/images/jet/g700/bg.jpg";
import EnquiryForm from "../../component/enquiryform/EnquiryForm";
import leaf from "../../assets/images/jet/leaf.svg";
import seat from "../../assets/images/jet/seat.svg";
import meter from "../../assets/images/jet/meter.svg";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import d1 from "../../assets/images/jet/g700/uu3.jpg";
import d2 from "../../assets/images/jet/g700/uu1.jpg";
import p1 from "../../assets/images/jet/g700/front.jpg";
import p2 from "../../assets/images/jet/g700/uu2.jpg";
import p3 from "../../assets/images/jet/g700/uu6.jpg";

export default function G700() {
  useEffect(() => {
    window.scrollTo(0, 0);
  });
  return (
    <>
      <section className="jetdetails">
        <div className="banners">
          <div>
            <div className="bgjetg700">
              <img src={bg} alt="" />
            </div>
            <div className="text-section mt-7">
              <div>
                <div className="flex texthead">
                  <h2>Gulfstream</h2>
                </div>
                <h3>G700</h3>
              </div>
              <div className="mt-60">
                <EnquiryForm title="get in touch" />
              </div>
            </div>
            <div className="scroll-down">
              <div className="point"></div>
            </div>
          </div>
        </div>
        <div className="best">
          <div className="heading">
            <h2>best comfort in the segment</h2>
          </div>
          <div className="md:flex cont mt-8">
            <div className="basis-1/3 contbody">
              <div className="imgcont">
                <img src={leaf} alt="" />
              </div>
              <p> Your comfort our priority</p>
            </div>
            <div className="basis-1/3 contbody">
              <div className="imgcont">
                <img src={seat} alt="" />
              </div>
              <p>Stay entertained at 45,000 feet</p>
            </div>
            <div className="basis-1/3 contbody">
              <div className="imgcont">
                <img src={meter} alt="" />
              </div>
              <p>equipped with state-of-the-art avionics</p>
            </div>
          </div>
        </div>
        <Sliders />
        <div className="about">
          <div className="lg:flex items-center">
            <div className="basis-1/2">
              <img src={d1} alt="" className="imgss" />
            </div>
            <div className="basis-1/2">
              <h5>THE ULTIMATE GULFSTREAM CABIN</h5>
              <p>
                Life is lived in the moments between the milestones. Maximize
                every minute with business aviation’s most spacious and flexible
                interior. Design your cabin with up to five living areas,
                including the all-new ultra galley and a grand suite with
                shower, to create your ideal flight experience. Embark on a new
                era of private jet ownership with Seven Air Aviation – where
                every flight is an expression of opulence, precision, and
                exclusivity. As the epitome of sophistication and exclusivity,
                we redefine the skies with our unrivaled selection of private
                jets for sale. Your journey to the pinnacle of aviation luxury
                starts here. Welcome aboard!
              </p>
            </div>
          </div>
          <div className="aboutbody">
            <div className="cards">
              <h4>DAZZLING POWER EFFORTLESS PERFORMANCE LIMITLESS comfort</h4>
            </div>
            <EnquiryForm title="make an Inquiry" />
          </div>
        </div>
        <div className="bgimg">
          <img src={bgg700} alt="" className="object-cover" />
        </div>
        <div className="about">
          <div className="lg:flex items-center">
            <div className="basis-1/2">
              <h5>
                Your top choice for jet excellence, elevating your travel
                experience with passion and commitment to perfection.
              </h5>
              <div>
                <ol>
                  <li>
                    <span className="text-white">
                      <strong>Unparalleled Elegance in the Skies:</strong>
                    </span>{" "}
                    Embark on a journey of opulence as you explore our
                    meticulously curated collection of private jets. Each
                    aircraft in our inventory is a testament to the finest
                    craftsmanship, cutting-edge technology, and exquisite
                    design. Whether you seek the sleek lines of a modern marvel
                    or the timeless elegance of a classic model, our diverse
                    fleet ensures that your discerning taste finds its perfect
                    match in the sky.
                  </li>
                  <li>
                    <span className="text-white">
                      <strong>Global Reach, Seamless Service:</strong>
                    </span>{" "}
                    With a worldwide presence, we transcend geographical
                    boundaries to serve clients across the globe. Our commitment
                    to excellence extends beyond the purchase process, as we
                    provide a seamless ownership experience. From maintenance
                    and upgrades to global concierge services, we are here to
                    make every aspect of your private jet ownership effortless
                    and enjoyable.
                  </li>
                  <li>
                    <span className="text-white">
                      <strong>Elevate Your Expectations:</strong>
                    </span>{" "}
                    Discover a new standard of luxury with Seven Air. Our
                    commitment to providing the best in jets is reflected not
                    only in our aircraft but also in the unparalleled level of
                    service we offer. Elevate your expectations and experience
                    the freedom to soar above the ordinary, embracing a
                    lifestyle where every journey is an indulgence in
                    sophistication and style.
                  </li>
                  <li>
                    <span className="text-white">
                      <strong>
                        Tailored Solutions for Discerning Clients:
                      </strong>
                    </span>{" "}
                    At Seven Air, we understand that every client is unique,
                    deserving a personalized approach to jet ownership. Our team
                    of aviation experts is dedicated to crafting bespoke
                    solutions that align with your individual preferences,
                    travel needs, and aspirations. From customization options to
                    comprehensive support, we go above and beyond to ensure that
                    your private jet experience is nothing short of
                    extraordinary.
                  </li>
                </ol>
              </div>
            </div>
            <div className="basis-1/2 flex justify-center">
              <img src={d2} alt="" className="imgss" />
            </div>
          </div>
        </div>
        <div className="mcards">
          <div className="md:flex justify-evenly">
            <div className="img3">
              <img src={p1} alt="" className="m-auto mb-4" />
            </div>
            <div className="img3">
              <img src={p2} alt="" className="m-auto mb-4" />
            </div>
            <div className="img3">
              <img src={p3} alt="" className="m-auto mb-4" />
            </div>
          </div>
          <EnquiryForm title="make an inquiry" />
        </div>
      </section>
    </>
  );
}

const Sliders = () => {
  const data = [
    {
      img: require("../../assets/images/jet/g700/imgg1.jpg"),
    },
    {
      img: require("../../assets/images/jet/g700/imgg2.jpg"),
    },
    {
      img: require("../../assets/images/jet/g700/imgg3.jpg"),
    },
    {
      img: require("../../assets/images/jet/g700/uu2.jpg"),
    },

    {
      img: require("../../assets/images/jet/g700/uu4.jpg"),
    },
    {
      img: require("../../assets/images/jet/g700/uu5.jpg"),
    },
    {
      img: require("../../assets/images/jet/g700/uu6.jpg"),
    },
    {
      img: require("../../assets/images/jet/g700/uu7.jpg"),
    },
  ];
  const settings = {
    autoplay: true,
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: "true",
  };
  return (
    <>
      <div className="jetslider">
        <Slider {...settings} className="sldiers">
          {data.map((item, index) => (
            <div key={index} className="cards">
              <div className="">
                <div>
                  <img src={item.img} alt="" className="imgss" />
                </div>
              </div>
            </div>
          ))}
        </Slider>
      </div>
    </>
  );
};
