import React, { useState } from "react";
// import axios from "../../utils/axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { Formik, Form } from "formik";
import OtpInput from "react-otp-input";
import { otpValidator } from "../../utils/validation";
import Overlay from "../overlay";
import "./style.css";
import cross from "../../assets/images/cross.svg";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "../../utils/axios";

const OtpPage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const stateData = location.state;
  console.log(stateData);
  const [isLoading, setIsLoading] = useState(false);
  const [OTPFormValues, setOtpFormValues] = useState({
    email: "",
    verificationCode: "",
  });

  const verifyOTP = async (values) => {
    setOtpFormValues(values);
    setIsLoading(true);

    try {
      const { data } = await axios.post("/otpVerify", {
        email: stateData,
        otp: values.verificationCode,
      });

      setIsLoading(false);
      console.log(data);
      navigate("/thanku");
    } catch (error) {
      setIsLoading(false);

      if (error?.response?.data?.errors) {
        toast.error(`${error.response.data.errors[0].msg}`, {
          position: toast.POSITION.TOP_RIGHT,
        });
      } else {
        toast.error(`${error?.response?.data?.message}`, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    }
  };
  return (
    <>
      <div className={` Contactusform`}>
        <div className="head">
          <img
            src={cross}
            alt="Close"
            className="cursor-pointer"
            onClick={() => navigate("/")}
          />
        </div>
        <div className="otpbody">
          <h5>Enter OTP</h5>
          <p>OTP has been sent to your Email address: {stateData}</p>
          <div>
            <Formik
              enableReinitialize
              initialValues={OTPFormValues}
              validate={(values) => otpValidator(values)}
              validateOnChange
              onSubmit={verifyOTP}
            >
              {(formikBag) => (
                <Form className="formStyle fotp">
                  <OtpInput
                    value={formikBag.values.verificationCode}
                    onChange={(e) =>
                      formikBag.setFieldValue("verificationCode", e)
                    }
                    numInputs={4}
                    renderInput={(props) => <input {...props} />}
                    error={
                      formikBag.touched.verificationCode &&
                      formikBag.errors.verificationCode
                        ? formikBag.errors.verificationCode
                        : null
                    }
                  />

                  <div className="text-center button1 mt-10">
                    <button type="submit">Submit</button>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
      <ToastContainer />
      {isLoading && <Overlay />}
    </>
  );
};

export default OtpPage;
