import React, { useEffect } from "react";
import bg from "../../assets/images/deal/bg.png";
import "./style.css";
import Slider from "react-slick";
function Privatejetpage() {
  useEffect(() => {
    window.scrollTo(0, 0);
  });
  return (
    <>
      <section className="dealwith">
        <div className="banner">
          <div>
            <img src={bg} alt="" />
            <div className="scroll-down">
              <div className="point"></div>
            </div>
          </div>
        </div>
        <PrivateJet />
        <Content />
      </section>
    </>
  );
}

export default Privatejetpage;

const PrivateJet = () => {
  const settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const data = [
    {
      img: require("../../assets/images/deal/pr1.png"),
      title: `2000 Gulfstream G200`,
      range: "7500 NM",
      passengers: 16,
    },
    {
      img: require("../../assets/images/deal/pr2.png"),
      title: "2008 Global 5000",
      range: "6500NM",
      passengers: 14,
    },
    {
      img: require("../../assets/images/deal/pr3.png"),
      title: "2000 Gulfstream G550",
      range: "6500NM",
      passengers: 14,
    },
    {
      img: require("../../assets/images/deal/pr1.png"),
      title: `2000 Gulfstream G200`,
      range: "7500 NM",
      passengers: 16,
    },
    {
      img: require("../../assets/images/deal/pr2.png"),
      title: "2008 Global 5000",
      range: "6500NM",
      passengers: 14,
    },
    {
      img: require("../../assets/images/deal/pr3.png"),
      title: "2000 Gulfstream G550",
      range: "6500NM",
      passengers: 14,
    },
  ];
  return (
    <>
      <div className="jetcont">
        <div className="heading">
          <h2>private jet</h2>
          <p className="pt-4">
            Here at Seven Air Aviation, we redefine private jets sales and
            acquisition experience by providing unparalleled services in the
            process for premium private jets. With a commitment to excellence
            and a passion for aviation, we are your trusted partner in elevating
            this wonderful moments.
          </p>
        </div>
        <div className="slider">
          <Slider {...settings}>
            {data.map((item, index) => {
              return (
                <>
                  <div className="cards" key={index}>
                    <div className="flex items-center justify-center flex-col">
                      <div>
                        <div>
                          <img src={item.img} alt="" />
                        </div>
                        <div className="pt-4">
                          <p className="title">{item.title}</p>
                          <h6>{item.range}</h6>
                          <h6>{item.passengers}</h6>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              );
            })}
          </Slider>
        </div>
      </div>
    </>
  );
};

const Content = () => {
  return (
    <>
      <div className="Content">
        <h3>Elevate Your Journey</h3>
        <h4>About Us</h4>
        <p>
          Here at Seven Air Aviation, we redefine private jets sales and
          acquisition experience by providing unparalleled services in the
          process for premium private jets. With a commitment to excellence and
          a passion for aviation, we are your trusted partner in elevating this
          wonderful moments.
        </p>
        <h4>Luxury Jet Sales</h4>
        <p>
          Discover a world of opulence with our curated selection of exquisite
          private jets from our partner reputable jet manufacturers. Whether you
          are a seasoned aviator or a first-time buyer, our world class team of
          experts is dedicated to assisting you in finding the perfect aircraft
          to suit your unique needs. From sleek and stylish cabins to
          cutting-edge technology, our collection features the epitome of luxury
          in the skies.
        </p>
        <h4>Personalized Acquisitions</h4>
        <p>
          Embark on a seamless journey to owning your dream private jet with our
          personalized acquisition services. Our team takes pride in
          understanding your preferences, budget, and requirements to source and
          negotiate on your behalf. Let us navigate the complexities of the
          acquisition process, ensuring a smooth and stress-free experience for
          you without being like a sharks like our competition.
        </p>

        <h4>Why Choose Seven Air Aviation?</h4>
        <ul>
          <li>
            Expert Guidance: Benefit from the expertise of our seasoned
            professionals who not only have a deep understanding of the private
            aviation market but deeply passionate as well.
          </li>
          <li>
            Tailored Solutions: Every client is unique, and so are their
            preferences. We tailor our services to meet your specific needs,
            ensuring a bespoke experience.
          </li>
          <li>
            Transparency: We prioritize transparency in all our transactions.
            From detailed inspections to clear contractual terms, we keep you
            informed at every step.
          </li>
          <li>
            Global Network: With a vast network of industry connections, we have
            access to a diverse range of private jets, providing you with
            extensive options.
          </li>
          <li>
            Excellence in Service: Expect nothing less than exceptional service
            with extreme higher standards. Our commitment to excellence extends
            beyond the transaction – we are here for you throughout your
            ownership journey.
          </li>
        </ul>
        <h4>Contact Us</h4>
        <p>
          Ready to embark on the ultimate luxury private jet acquisition
          experience? Contact Seven Air Aviation team today to explore our range
          of luxury private jets and bespoke acquisition services. Elevate your
          journey with us.
        </p>
      </div>
    </>
  );
};
