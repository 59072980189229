import axios from "axios";
import { API_URL } from "./static";

const instance = axios.create({
  baseURL: API_URL,
});

// console.log(API_URL);

instance.defaults.headers.common["Access-Control-Allow-Origin"] = "*";

export default instance;
