import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./style.css";
import up from "../../assets/images/testonomial/up.png";
import down from "../../assets/images/testonomial/down.png";
function Testimonial() {
  const data = [
    {
      img: require("../../assets/images/testonomial/c1.jpg"),
      name: `L. McTavish`,
      post: "Ship Building & Logistics, Park Avenue NY",
      desc: "Seven Air Aviation exceeded my expectations with personalized service, transparency, and an extraordinary fleet. Their commitment to excellence made my private jet ownership a luxurious and seamless experience. Highly recommended!",
    },
    {
      img: require("../../assets/images/testonomial/ap.jpg"),
      name: `Stanley Fisher`,
      post: "Co-CEO of Architectural Pioneers",
      desc: "Choosing Seven Air Aviation for our corporate jet investment has been transformative. Their unmatched service, attention to detail, and commitment to transparency make them our strategic partner. A year in, our decision continues to elevate our business travel experience.",
    },
  ];

  const [show, setShow] = useState(true);

  useEffect(() => {
    if (data.length <= 3) {
      setShow(true);
    } else {
      setShow(false);
    }
  }, [data.length]);

  const settings = {
    autoplay: true,
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
    arrows: "true",
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <>
      <section className="testimonial ">
        <div className="heading">
          <h2>What our customer say</h2>
        </div>
        <div className="sildertest">
          {show ? (
            <div className="flex flex-wrap justify-center sm:px-10">
              {data.map((item, index) => (
                <div className="lg:basis-1/3 md:basis-1/2 mb-5 mx-6">
                  <div key={index} className="cards">
                    <div className="flex items-center justify-center flex-col">
                      <div className="text-center w-full">
                        <div className="flex items-center justify-center cimg">
                          <img src={item.img} alt="" />
                        </div>
                        <div className="pt-4">
                          <h4 className="pb-2 text-white">{item.name}</h4>
                          <h6 className="text-white">{item.post}</h6>
                          <div className="flex dec">
                            <div>
                              <img src={up} alt="" />
                            </div>
                            <p className="text-white py-5">{item.desc}</p>
                            <div className="self-end">
                              <img src={down} alt="" />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          ) : (
            <Slider {...settings}>
              {data.map((item, index) => (
                <div key={index} className="cards">
                  <div className="flex items-center justify-center flex-col">
                    <div className="text-center w-full">
                      <div className="flex items-center justify-center cimg">
                        <img src={item.img} alt="" />
                      </div>
                      <div className="ps-8 pt-4">
                        <h4 className="pb-2 text-white">{item.name}</h4>
                        <h6 className="text-white">{item.post}</h6>
                        <div className="flex dec">
                          <div>
                            <img src={up} alt="" />
                          </div>
                          <p className="text-white py-5">{item.desc}</p>
                          <div className="self-end">
                            <img src={down} alt="" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </Slider>
          )}
        </div>
      </section>
    </>
  );
}

export default Testimonial;
