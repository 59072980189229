import React, { useEffect } from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./Compromise.css";
import s1 from "../../assets/images/Compromise/s1.png";
import s2 from "../../assets/images/Compromise/s2.png";
import s3 from "../../assets/images/Compromise/s3.png";
import s4 from "../../assets/images/Compromise/s4.png";
import s5 from "../../assets/images/Compromise/s5.png";
import s6 from "../../assets/images/Compromise/s6.png";
import s7 from "../../assets/images/Compromise/s7.png";
import s8 from "../../assets/images/Compromise/s8.png";
import s9 from "../../assets/images/Compromise/s9.png";
import a1 from "../../assets/images/Compromise/a1.png";
import a2 from "../../assets/images/Compromise/a2.png";
import a3 from "../../assets/images/Compromise/a3.png";
import a4 from "../../assets/images/Compromise/a4.png";
import a5 from "../../assets/images/Compromise/a5.png";
import a6 from "../../assets/images/Compromise/a6.png";
import a7 from "../../assets/images/Compromise/a7.png";
import a8 from "../../assets/images/Compromise/a8.png";
import a9 from "../../assets/images/Compromise/a9.png";

function Compromise() {
  return (
    <>
      <section className="Compromise">
        <div>
          <div className="heading">
            <h2 className="pb-5">No Compromise with your comfort</h2>
            <p>
              Here at Seven Air Aviation, we understand that the pursuit of
              private jet ownership goes beyond the thrill of flying, it's about
              elevating your travel experience to new heights. In our commitment
              to delivering the epitome of luxury and comfort, we leave no stone
              unturned – ensuring that your time in the skies is nothing short
              of extraordinary and valuable.
            </p>
          </div>
          <Slider1 />
          <Slider2 />
        </div>
      </section>
    </>
  );
}

export default Compromise;

const Slider1 = () => {
  useEffect(() => {
    const scrollers = document.querySelectorAll(".scroller");

    if (!window.matchMedia("(prefers-reduced-motion: reduce)").matches) {
      addAnimation();
    }

    function addAnimation() {
      scrollers.forEach((scroller) => {
        scroller.setAttribute("data-animated", true);

        const scrollerInner = scroller.querySelector(".scroller__inner");

        if (scrollerInner) {
          const scrollerContent = Array.from(scrollerInner.children);

          scrollerContent.forEach((item) => {
            const duplicatedItem = item.cloneNode(true);
            duplicatedItem.setAttribute("aria-hidden", true);
            scrollerInner.appendChild(duplicatedItem);
          });
        }
      });
    }
  }, []);
  return (
    <>
      <div className="sbody">
        <div className="scroller" data-direction="left" data-speed="slow">
          <div className="scroller__inner">
            {[s1, s2, s3, s4, s5, s6, s7, s8, s9].map((image, index) => (
              <div key={index}>
                <img src={image} alt="" />
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

const Slider2 = () => {
  useEffect(() => {
    const scrollers = document.querySelectorAll(".scroller");

    if (!window.matchMedia("(prefers-reduced-motion: reduce)").matches) {
      addAnimation();
    }

    function addAnimation() {
      scrollers.forEach((scroller) => {
        scroller.setAttribute("data-animated", true);

        const scrollerInner = scroller.querySelector(".scroller__inner");

        if (scrollerInner) {
          const scrollerContent = Array.from(scrollerInner.children);

          scrollerContent.forEach((item) => {
            const duplicatedItem = item.cloneNode(true);
            duplicatedItem.setAttribute("aria-hidden", true);
            scrollerInner.appendChild(duplicatedItem);
          });
        }
      });
    }
  }, []);

  return (
    <>
      <div className="sbody">
        <div className="scroller" data-direction="right" data-speed="slow">
          <div className="scroller__inner">
            {[a1, a2, a3, a4, a5, a6, a7, a8, a9].map((image, index) => (
              <div key={index}>
                <img src={image} alt="" />
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};
