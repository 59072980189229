import React, { useEffect } from "react";
import bg from "../../assets/images/aviation/bg.png";
import "./style.css";
import p1 from "../../assets/images/aviation/p1.png";
import p2 from "../../assets/images/aviation/p2.png";
import p3 from "../../assets/images/aviation/p3.png";
import p4 from "../../assets/images/aviation/p4.png";
import EnquiryForm from "../../component/enquiryform/EnquiryForm";
function AviationFuel() {
  useEffect(() => {
    window.scrollTo(0, 0);
  });
  return (
    <>
      <section className="aviation">
        <div className="banner">
          <div>
            <img src={bg} alt="" className="imgb" />
            <div className="text-section">
              <h1>Face the future of</h1>
              <h3>Low-carbon Travel</h3>
              <EnquiryForm title="get in touch" />
            </div>
          </div>
        </div>
        <div className="about">
          <div className="aboutbody md:flex items-center">
            <div className="basis-1/2 img-parts ">
              <img src={p1} alt="" />
            </div>
            <div className="basis-1/2 text-part">
              <h3>Why Choose Us for your Bulk Aviation Fuel ?</h3>
              <p>
                Seven Air Aviation has the solutions and skilled personnel
                required to support today’s turbulent business environment and
                apply stable resources that ensure sustained customer
                satisfaction. We provide professional logistics and delivery
                services.{" "}
              </p>
            </div>
          </div>
          <div className="aboutbody flex md:flex-row flex-col-reverse items-center">
            <div className="basis-1/2 text-part">
              <ul>
                <li>Get Access to Expertise and Experience</li>
              </ul>
              <p>
                In the present complex worldwide market situation, it is hard to
                expect and oblige inside mastery in every one of the limits and
                areas required. A 3PL specialist co-op will have information and
                involvement with issues like vehicle documentation, import and
                product, global consistence and financial guidelines, for
                example. Organizations hoping to venture into worldwide business
                sectors can profit from the coordinated operations backing and
                expertise that their accomplice can give.
              </p>
            </div>
            <div className="basis-1/2 img-parts">
              <img src={p2} alt="" />
            </div>
          </div>
          <div className="aboutbody md:flex items-center">
            <div className="basis-1/2 img-parts">
              <img src={p3} alt="" />
            </div>
            <div className="basis-1/2 text-part">
              <ul>
                <li>Focus on Core Competencies</li>
              </ul>
              <p>
                Outsourcing logistics will give your organization the leeway to
                focus on its core competencies instead of getting involved in
                the management of non-core but critical functions. Your business
                can enjoy the benefits of logistical expertise without deploying
                internal resources. The company is enormously diversified and
                renders potential customers globally unique services and ensures
                customers guarantee and safety.
              </p>
            </div>
          </div>
          <div className="aboutbody flex md:flex-row flex-col-reverse items-center">
            <div className="basis-1/2 text-part">
              <ul>
                <li>Gain Flexibility and Scalability</li>
              </ul>
              <p>
                At Seven Air Aviation together with our partners in the
                industries; the advantage of third-party logistics in supply
                chain management is that it offers enterprises the flexibility
                and scalability to utilize supply and distribution resources
                based on current business needs. Thus, when sales are down,
                there are no redundant investments and unutilized resources, and
                when there’s a surge in demand, enterprises can upscale.
              </p>
            </div>
            <div className="basis-1/2 img-parts">
              <img src={p4} alt="" />
            </div>
          </div>
        </div>
        <div className="req flex flex-col justify-center items-center">
          <h3 className="px-4 text-center">Requirements for Bulk Purchase?</h3>
          <EnquiryForm title="make an Inquiry" />
        </div>

        <div className="cif">
          <div className="heading mb-4">
            <h2>CIF PROCEDURES</h2>
          </div>
          <div className="mainul">
            <ol>
              <li>
                BUYER ISSUE ICPO MUST BE WITH BUYER COMPANY LETTERHEAD AND BUYER
                BANKING INFORMATION.
              </li>
              <li>SELLER ISSUE DRAFT CONTRACT TO BUYER.</li>
              <li>BUYER SIGN AND RETURN BACK TO SELLER.</li>
              <li>
                SELLER ISSUE THE PPOP DOCUMENTS TO BUYER VIA EMAIL ADDRESS SUCH
                AS:
                <ul className="">
                  <li>STATEMENT OF AVAILABILITY OF PRODUCT</li>
                  <li>COMMITMENT TO SUPPLY</li>
                  <li>CERTIFICATE OF ORIGIN</li>
                  <li>
                    PRODUCT QUALITY, PASSPORT (ANALYSIS TEST REPORT) BY THE
                    COUNTRY OF ORIGIN (EG. AZERBAIJANI )STANDARD GOST AZS.
                  </li>
                </ul>
              </li>
              <li>
                WITHIN 5 BANKING DAYS BUYER'S BANK ISSUE BG OR TRANSFERABLE
                OPERATIVE DLC VIA MT700 OR SBLC MT760 OR ESCROW PAYMENT
                ACCORDING TO SELLER'S FIDUCIARY BANK VERBIAGE TO SELLER
                NOMINATED FIDUCIARY OFFSHORE BANK ACCOUNT FOR FIRST MONTH
                SHIPMENT, SHOULD BUYER FAIL TO ISSUE PAYMENT INSTRUMENT WITHIN 5
                BANKING DAYS, BUYER WILL MAKE CASH DEPOSIT OF $ 350,000 BY TT
                WIRE TRANSFER FOR SECURITY.GUARANTEE TO ENABLE SELLER CHARTER
                VESSEL AND COMMENCE SHIPMENT, AND THIS PAYMENT WILL BE DEDUCTED
                FROM THE TOTAL COST OF PRODUCT AFTER INSPECTION AT DISCHARGE
                PORT, SELLER'S BANK ISSUES FULL POP DOCUMENTS TO THE BUYER'SBANK
                ALONGSIDE WITH THE 2% PERFORMANCE BOND (PB) TO THE BYYER.
                <ul className="">
                  <li>
                    Copy of license to export, issued by the department of the
                    Ministry of Energy, of ( COUNTRY OF ORIGIN) IN THIS CASE
                    ;EG. AZERBIJANI.
                  </li>
                  <li>
                    Copy of Approval to Export, issued by the Ministry of
                    Justice, AZERBIJANI.
                  </li>
                  <li>Copy of statement of availability of the product</li>
                  <li>
                    Copy of the refinery commitment to produce the product.
                  </li>
                  <li>
                    Copy of Transnet contract to transport the product to the
                    loading port.
                  </li>
                  <li>Copy of the port storage agreement.</li>
                  <li>
                    Copy of the charter party agreement to transport the product
                    to discharge port.
                  </li>
                  <li>Copy of Vessel Questionnaire 88.</li>
                  <li>Copy of Bill of Lading.</li>
                  <li>SGS Report at loading port.</li>
                  <li>Dip test Authorization (DTA) & ATB</li>
                  <li>NOR /ETA.</li>
                  <li>Certificate of Ownership Transfer.</li>
                </ul>
              </li>
            </ol>
          </div>

          <h6 className="boldheading">
            (Buyer completes form with contact information indicating they
            accepts the above Seller procedure)
          </h6>
        </div>
        <div className="fob">
          <div className="heading px-4">
            <h2 className="pb-4">FOR PROCEDURE :</h2>
            <p>CONTACT MANAGEMENT FOR UPDATED PROCEDURES</p>
          </div>
          <EnquiryForm title="get in touch" />
        </div>
      </section>
    </>
  );
}

export default AviationFuel;
