import React, { useEffect, useState } from "react";
import "./faq.css";
import { Faq, MainContainer, Quest, QuesContainer, Answer } from "./element.js";

import plus from "../../assets/images/img/plus.svg";
import minus from "../../assets/images/img/minus.svg";

// import axios from "../../utils/axios";
import { toast } from "react-toastify";
import Overlay from "../overlay.js";
import axios from "../../utils/axios";

const Faqbar = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState([]);
  console.log(data);
  const handleData = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get("/getFaq");
      setData(response?.data?.data || []);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      if (error?.response?.data?.errors) {
        toast.error(`${error.response.data.errors[0].msg}`, {
          position: toast.POSITION.TOP_RIGHT,
        });
      } else {
        toast.error(`${error?.response?.data?.message}`, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    }
  };

  useEffect(() => {
    handleData();
  }, []);

  const [searchQuery, setSearchQuery] = useState("");

  const filteredStrings =
    data?.filter((str) =>
      (str?.question).toLowerCase().includes(searchQuery.toLowerCase())
    ) || [];

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <section className="faqs">
        <Faq>
          <div className="heading flex justify-center w-full">
            <h2>Faq's</h2>
          </div>
        </Faq>
        <MainContainer>
          {filteredStrings?.map((currentEl) => {
            const { id } = currentEl;
            return <MyAccordion key={id} {...currentEl} />;
          })}
        </MainContainer>
      </section>
      {isLoading && <Overlay />}
    </>
  );
};

export default Faqbar;

const MyAccordion = ({ question, answer }) => {
  const [show, setShow] = useState(false);
  return (
    <>
      <QuesContainer>
        <Quest style={{ fontWeight: "500", marginBottom: "20px" }}>
          <div onClick={() => setShow(!show)} className="flex items-center">
            {show ? (
              <img
                src={minus}
                className="me-6"
                alt="img"
                style={{ width: "20px", height: "20px" }}
              />
            ) : (
              <img
                src={plus}
                alt="img"
                className="me-6"
                style={{ width: "20px", height: "20px" }}
              />
            )}{" "}
            {question}
          </div>
        </Quest>
        {show && (
          <Answer
            dangerouslySetInnerHTML={{ __html: answer }}
            style={{ fontFamily: "var(--pp-book)", fontSize: "18px" }}
          ></Answer>
        )}
      </QuesContainer>
    </>
  );
};
