import React, { useEffect, useState, useRef } from "react";
import axios from "../../utils/axios";
import { Formik, Form, ErrorMessage } from "formik";
import PhoneInput, { parsePhoneNumber } from "react-phone-number-input";
import "react-phone-number-input/style.css";
import { tour_enquiry } from "../../utils/validation";
import Overlay from "../overlay";
import "./style.scss";
import cross from "../../assets/images/cross.svg";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// import {
//   GoogleMap,
//   Autocomplete,
//   LoadScript,
//   StandaloneSearchBox,
//   useJsApiLoader,
// } from "@react-google-maps/api";
// const libraries = ["places"];

function TourInquiryForm(props) {
  const [show, setShow] = useState(false);

  const handleclick = () => {
    setShow(!show);
  };

  return (
    <div className="tourenquiry">
      <div className="button1 text-center mt-16">
        <button onClick={() => handleclick()}>{props.title}</button>
      </div>
      <TourFromBody handleclick={handleclick} show={show} />
    </div>
  );
}

export default TourInquiryForm;

const TourFromBody = ({ handleclick, show }) => {
  return (
    <>
      <ToastContainer />
      <div className={` ${show ? "tsfafter" : "tsfbefore"} Contactusform`}>
        <div className="head">
          <img src={cross} alt="Close" onClick={() => handleclick()} />
        </div>
        <ContactusForm handleclick={handleclick} />
      </div>
    </>
  );
};

const ContactusForm = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [userDetails, setUserDetails] = useState({
    firstName: "",
    lastName: "",
    mobileNumber: "",
    email: "",
    comments: "",
    jet_type: "",
    tour_title: "",
    // pickup_location: "",
    pickup_vehicle: "",
  });

  const handlesubmit = async (values) => {
    setIsLoading(true);

    try {
      const callingCode = parsePhoneNumber(values.mobileNumber);
      const countrycodeis = callingCode.countryCallingCode;
      const newphoneNumber = values.mobileNumber
        .replace(countrycodeis, "")
        .replace(/^(\+)+/, "")
        .trim();
      const formValues = {
        first_name: values.firstName,
        last_name: values.lastName,
        email: values.email,
        phone_number: `${newphoneNumber}`,
        country_code: `+${countrycodeis}`,
        description: values.comments,
        // pickup_location: values.pickup_location,
        tour_title: values.tour_title,
        pickup_vehicle: values.pickup_vehicle,
        jet_type: values.jet_type,
      };

      const { data } = await axios.post("/tourEnquiry", formValues);

      // console.log(data.message);

      setUserDetails({
        firstName: "",
        lastName: "",
        email: "",
        mobileNumber: "",
        comments: "",
        jet_type: "",
        tour_title: "",
        // pickup_location: "",
        pickup_vehicle: "",
      });
      setIsLoading(false);

      if (data.message === " OTP Sent Successfully") {
        navigate("/otp", { state: formValues.email });
        toast.success("Otp Sent Successfully");
      } else {
        navigate("/thanku");
        toast.success("Form Submit Successfully");
      }

      toast.success("Your form has been submitted successfully");
    } catch (error) {
      setIsLoading(false);
      console.error("Error submitting form:", error);
    }
  };

  const [tourTitle, setTourTitle] = useState();
  const [jetType, setJetType] = useState();
  const [vehicals, setVehcials] = useState();
  const handleDropDownData = async () => {
    try {
      const tourTitledata = await axios.get("/getTourTitles");
      const jetTypeData = await axios.get("/getJetType");
      const vehicalsData = await axios.get("/getPickupVehicle");
      setTourTitle(tourTitledata);
      setJetType(jetTypeData);
      setVehcials(vehicalsData);
    } catch (error) {}
  };

  useEffect(() => {
    handleDropDownData();
  }, []);

  const [selectedValue1, setSelectedValue1] = useState("");
  const handleChange1 = (e) => {
    setSelectedValue1(e.target.value);
  };
  const [selectedValue2, setSelectedValue2] = useState("");
  const handleChange2 = (e) => {
    setSelectedValue2(e.target.value);
  };
  const [selectedValue3, setSelectedValue3] = useState("");
  const handleChange3 = (e) => {
    setSelectedValue3(e.target.value);
  };
  // console.log(tourTitle?.data?.data, "TOUR TITLE");
  // console.log(jetType?.data?.data, "jet type");
  // console.log(vehicals?.data?.data, "vehical");
  //google address

  // const inputRef = useRef();

  // const handlePlaceChanged = (formikBag) => {
  //   const [place] = inputRef.current.getPlaces();
  //   if (place) {
  //     console.log(place);
  //     const addressComponents = place.address_components;
  //     console.log(addressComponents, "address_components");
  //     const address = place.formatted_address;
  //     //for zip code

  //     console.log(formikBag);
  //   }
  // };

  return (
    <>
      <div className="formbody">
        <h5 className="text-center headstext mb-14">Tour Inquiry</h5>
        <div>
          <Formik
            enableReinitialize
            initialValues={userDetails}
            validate={(values) => tour_enquiry(values)}
            validateOnChange
            onSubmit={(values) => {
              handlesubmit(values);
            }}
          >
            {(formikBag) => (
              <Form>
                {/* {console.log(formikBag)} */}
                <div className="d-flex">
                  <div className="sm:flex">
                    <div className="basis-1/2 px-4 mb-5">
                      <div className="form-field">
                        <input
                          type="text"
                          name="firstName"
                          className="text-capitalize text-white"
                          placeholder="First Name*"
                          value={formikBag.values.firstName}
                          onChange={(e) => {
                            formikBag.setFieldValue(
                              "firstName",
                              e.target.value
                            );
                          }}
                        />
                      </div>
                      <ErrorMessage
                        name="firstName"
                        component="div"
                        className="errormessage"
                      />
                    </div>
                    <div className="basis-1/2 px-4 mb-5">
                      <div className="form-field">
                        <input
                          type="text"
                          name="lastName"
                          className="text-capitalize text-white"
                          placeholder="Last Name*"
                          value={formikBag.values.lastName}
                          onChange={(e) => {
                            formikBag.setFieldValue("lastName", e.target.value);
                          }}
                        />
                      </div>
                      <ErrorMessage
                        name="lastName"
                        component="div"
                        className="errormessage"
                      />
                    </div>
                  </div>
                </div>
                <div className="sm:flex">
                  <div className=" basis-1/2 px-4 mb-5">
                    <div className="form-field">
                      <PhoneInput
                        international
                        defaultCountry="US"
                        name="mobileNumber"
                        className="w-100"
                        placeholder="Enter phone number"
                        value={formikBag?.values?.mobileNumber}
                        onChange={(value) => {
                          formikBag.setFieldValue("mobileNumber", value);
                        }}
                        error={
                          formikBag.touched.mobileNumber &&
                          formikBag.errors.mobileNumber
                            ? formikBag.errors.mobileNumber
                            : null
                        }
                      />
                    </div>
                    <p className="errormessage">
                      {formikBag.errors.mobileNumber}
                    </p>
                  </div>
                  <div className="basis-1/2 px-4 mb-5">
                    <div className="form-field">
                      <input
                        className="text-white"
                        type="email"
                        name="email"
                        placeholder="Email Address*"
                        value={formikBag.values.email}
                        onChange={(e) => {
                          formikBag.setFieldValue("email", e.target.value);
                        }}
                      />
                    </div>
                    <ErrorMessage
                      name="email"
                      component="div"
                      className="errormessage"
                    />
                  </div>
                </div>
                <div className="sm:flex">
                  <div className="basis-1/2 px-4 mb-5">
                    <div className="form-field">
                      <select
                        style={{ color: selectedValue2 ? "white" : "#979FA2" }}
                        className="w-full"
                        name="tour_title"
                        value={formikBag.values.tour_title}
                        onChange={(e) => {
                          handleChange2(e);
                          formikBag.setFieldValue("tour_title", e.target.value);
                        }}
                      >
                        <option value="" disabled selected>
                          Tour Title
                        </option>
                        {tourTitle?.data?.data?.map((item) => {
                          return (
                            <>
                              <option value={item}>{item}</option>
                            </>
                          );
                        })}
                      </select>
                    </div>
                    <ErrorMessage
                      name="tour_title"
                      component="div"
                      className="errormessage"
                    />
                  </div>
                  <div className="basis-1/2 px-4 mb-5">
                    <div className="form-field">
                      <select
                        style={{ color: selectedValue3 ? "white" : "#979FA2" }}
                        className="w-full"
                        name="jet_type"
                        value={formikBag.values.jet_type}
                        onChange={(e) => {
                          handleChange3(e);
                          formikBag.setFieldValue("jet_type", e.target.value);
                        }}
                      >
                        <option value="" disabled selected>
                          Jet Type
                        </option>
                        {jetType?.data?.data?.map((item) => {
                          return (
                            <>
                              <option value={item}>{item}</option>
                            </>
                          );
                        })}
                      </select>
                    </div>
                    <ErrorMessage
                      name="jet_type"
                      component="div"
                      className="errormessage"
                    />
                  </div>
                </div>

                <div className="sm:flex">
                  <div className="basis-1/2 px-4 mb-5">
                    <div className="form-field">
                      <select
                        style={{ color: selectedValue1 ? "white" : "#979FA2" }}
                        className="w-full"
                        name="pickup_vehicle"
                        value={formikBag.values.pickup_vehicle}
                        onChange={(e) => {
                          handleChange1(e);
                          formikBag.setFieldValue(
                            "pickup_vehicle",
                            e.target.value
                          );
                        }}
                      >
                        <option value="" disabled selected>
                          Pickup Vehicle
                        </option>
                        {vehicals?.data?.data?.map((item) => {
                          return (
                            <>
                              <option value={item}>{item}</option>
                            </>
                          );
                        })}
                      </select>
                    </div>
                    <ErrorMessage
                      name="pickup_vehicle"
                      component="div"
                      className="errormessage"
                    />
                  </div>
                  {/* <div className="basis-1/2 px-4 mb-5">
                    <div className="form-field">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter Location"
                        name="pickup_location"
                        value={formikBag.values.pickup_location}
                        onChange={(e) => {
                          formikBag.setFieldValue(
                            "pickup_location",
                            e.target.value
                          );
                        }}
                      />
                    </div>
                    <ErrorMessage
                      name="pickup_location"
                      component="div"
                      className="errormessage"
                    />
                  </div> */}

                  {/* <div className="basis-1/2 px-4 mb-5">
                    <div className="form-field">
                      <LoadScript
                        googleMapsApiKey="AIzaSyBMb3GRWhrkiBVFqL53LunZOPEJGVhPJas"
                        libraries={["places"]}
                      >
                        <StandaloneSearchBox
                          onLoad={(ref) => (inputRef.current = ref)}
                          onPlacesChanged={() => {
                            handlePlaceChanged(formikBag);
                          }}
                        >
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Enter Location"
                            name="pickup_location"
                            value={formikBag.values.pickup_location || ""}
                            onChange={(e) => {
                              formikBag.handleChange(e);
                              formikBag.setFieldValue(
                                "pickup_location",
                                e.target.value
                              );
                            }}
                          />
                        </StandaloneSearchBox>
                      </LoadScript>
                      <ErrorMessage
                        name="pickup_location"
                        component="div"
                        className="errormessage"
                      />
                    </div>
                  </div> */}
                </div>
                <div className="px-4">
                  <div className="form-field flex items-end">
                    <textarea
                      name="comments"
                      className="text-white"
                      placeholder="Specify Your Inquiry*"
                      value={formikBag.values.comments}
                      onChange={(e) => {
                        const inputValue = e.target.value;
                        if (inputValue.length <= 250) {
                          formikBag.setFieldValue("comments", inputValue);
                        }
                      }}
                    ></textarea>
                    <div className="text-white ps-4">
                      {formikBag.values.comments.length}/250
                    </div>
                  </div>
                  <ErrorMessage
                    name="comments"
                    component="div"
                    className="errormessage"
                  />
                </div>
                <div className="text-center button1 mt-10">
                  <button type="submit">send request</button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
      {isLoading && <Overlay />}
    </>
  );
};
