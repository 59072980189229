import React, { useState } from "react";
import "./enquirybody.css";
import "react-phone-number-input/style.css";
import EnquiryForm from "../enquiryform/EnquiryForm";

function Enquirybody() {
  const [show, setShow] = useState(false);
  // const handleclick = () => {
  //   setShow(!show);
  // };
  return (
    <>
      <div className="contactus">
        <div className="heading none">
          <h2>HOW IT WORKS</h2>
        </div>
        <div className="flex flex-wrap justify-evenly items-start mt-16">
          <div className="contcard lg:basis-1/4 sm:bais-1/2 mb-8">
            <div className="crl">1</div>
            <h3 className="pt-6">
              Consultation and Needs Assessment Personalized Exploration
            </h3>
            <p className="pt-5">
              Your journey begins with a personalized consultation. Our team of
              aviation experts takes the time to understand your unique needs,
              preferences, and operational requirements. We discuss your travel
              patterns, the number of passengers, desired features, and any
              specific customization you may desire.
            </p>
          </div>
          <div className="contcard lg:basis-1/4 md:bais-1/2 mb-8">
            <div className="crl">2</div>
            <h3 className="pt-6">
              Aircraft Selection and Proposal Curated Options
            </h3>
            <p className="pt-5">
              Based on the insights gathered during the consultation, we present
              you with a curated selection of jet options. Each recommendation
              is carefully chosen to match your preferences and operational
              needs. Explore a range of models, from efficient and economical
              options to luxurious and feature-rich aircraft.
            </p>
          </div>
          <div className="contcard lg:basis-1/4 md:bais-1/2 mb-8">
            <div className="crl">3</div>
            <h3 className="pt-6">
              {" "}
              Inspection and Verification Thorough Inspection
            </h3>
            <p className="pt-5">
              Prior to finalizing the deal, our team conducts a thorough
              inspection of the selected jet. We ensure that the aircraft meets
              all safety and performance standards. Any discrepancies are
              addressed, and you have the opportunity to perform your own
              inspection to guarantee satisfaction.
            </p>
          </div>
        </div>
        <EnquiryForm title="get in touch" />
      </div>
    </>
  );
}

export default Enquirybody;
