import React, { useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import "./style.css";
import pdflogo from "../../assets/images/icon/pdfloogo.png";
import downlaodlogo from "../../assets/images/icon/downloadlogo.png";

function UnlockingDetails() {
  const location = useLocation();

  const { state } = location;
  useEffect(() => {
    window.scrollTo(0, 0);
  });
  const handleDownload = () => {
    window.open(state?.pdf, "_blank");
  };
  return (
    <>
      <section className="unlocking_deatils">
        <div className="content">
          <div className="heading">
            <h2>Unlocking Possibilities</h2>
            <p>Our Diverse Range of Services</p>
          </div>
          <h3 className="mainheading text-center">{state?.name}</h3>
          <div className="lines"></div>
          {state?.pdf != null ? (
            <>
              <div className="pdfcont">
                <div className="flex items-center">
                  <img src={pdflogo} alt="img" />
                  <p className="ps-3">Aircraft Financing Checklist.pdf</p>
                </div>
                <div>
                  <Link
                    to={state?.pdf}
                    target="_blank"
                    onClick={handleDownload}
                  >
                    <img src={downlaodlogo} alt="img" />
                  </Link>
                </div>
              </div>
            </>
          ) : null}
          <div
            className="dec"
            dangerouslySetInnerHTML={{
              __html: state?.desc,
            }}
          ></div>
        </div>
        <div className="imgcont flex">
          {state?.img?.map((item, index) => (
            <img key={index} src={item} alt={`img ${index}`} />
          ))}
        </div>
      </section>
    </>
  );
}

export default UnlockingDetails;
