import React from "react";
import styled, { withTheme } from "styled-components";
// import { FlexWrapper } from "./StyledComponents";

const OverWrapper = styled.div(
  (props) => `
    display: flex;
    flex-direction: ${props.direction || "column"};
    justify-content: ${props.justifyContent || "center"};
    align-items: ${props.alignItems || "center"};
    position:${props.position ? props.position : "fixed"} ;
    width: 100%;
    height: ${props.viewheight ? props.viewheight : "100vh"};
    top: 0;
    height: 100vh;
    overflow: hidden;
    left: 0;
    right: 0;
    bottom: 0;
    background-color:${props.backgroundColor || "rgba(0, 0, 0, 0.5)"};
    z-index: 9999999;
    cursor: pointer;
    
`
);

function Overlay(props) {
  const { position, direction, backgroundColor, viewheight } = props;
  return (
    <OverWrapper
      position={position}
      direction={direction}
      backgroundColor={backgroundColor}
      viewheight={viewheight}
    >
      <div class="loader">
        <div class="circle"></div>
        <div class="circle"></div>
        <div class="circle"></div>
        <div class="circle"></div>
      </div>
    </OverWrapper>
  );
}

export default withTheme(Overlay);
