import React from "react";
import cross from "../../assets/images/cross.svg";
import { useNavigate } from "react-router-dom";

const Thankupage = () => {
  const navigate = useNavigate();

  return (
    <>
      <div className={`Contactusform`}>
        <div className="head">
          <img
            src={cross}
            alt="Close"
            className="cursor-pointer"
            onClick={() => navigate("/")}
          />
        </div>
        <div className="thanks">
          <div>
            <img
              src="https://sevenairimage.s3.us-east-2.amazonaws.com/video/1704696678828.mp4"
              alt="Thank You Video"
            />
          </div>
          <h5>Thank you</h5>
          <p>
            Your submission has been received. <br /> We will be in touch and
            contact you soon!
          </p>
          <div className="text-center button1 mt-10">
            <button onClick={() => navigate("/")}>Back to Home</button>
          </div>
        </div>
      </div>
    </>
  );
};

export default Thankupage;
