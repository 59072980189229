import React, { useState, useRef } from "react";
import cross from "../../assets/images/cross.svg";
import { Formik, Form, ErrorMessage } from "formik";
// import axios from "../../utils/axios";
import PhoneInput, { parsePhoneNumber } from "react-phone-number-input";
import "react-phone-number-input/style.css";
import { buyform } from "../../utils/validation";
import Overlay from "../overlay";
import "./style.css";
import plus from "../../assets/images/plus.svg";
import { Link, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import pdfimg from "../../assets/images/pdf.svg";
import deleteimg from "../../assets/images/delete.svg";
import deleter from "../../assets/images/deleter.svg";
import axios from "../../utils/axios";

function BuyForm() {
  const navigate = useNavigate();

  const [show, setShow] = useState(true);
  // console.log(setShow);
  return (
    <>
      <section className="buyform">
        <div className="Contactusform">
          <div className="head">
            <img
              src={cross}
              alt=""
              className="cursor-pointer"
              onClick={() => navigate("/")}
            />
          </div>
          <ContactusForm />
        </div>
      </section>
      <ToastContainer />
    </>
  );
}

export default BuyForm;

const ContactusForm = (formikBag) => {
  const [checkbox, setCheckbox] = useState(false);
  const handleCheckbox = ({ formikBag }) => {
    setCheckbox(!checkbox);
    if (checkbox === true) {
      formikBag.setFieldValue();
    }
  };

  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);
  const [userDetails, setUserDetails] = useState({
    firstName: "",
    lastName: "",
    mobileNumber: "",
    email: "",
    comments: "",
    highlights: "",
    airframe: "",
    aircraftService: "",
    interior: "",
    floorPlan: "",
    images: [],
    engine_detail_pdf: "",
    apu_pdf: "",
    avionics_pdf: "",
    aircraft_name: "",
    aircraft_category: "",
    maintenance: "",
    possess_jet: "no",
  });

  const handlesubmit = async (values) => {
    setIsLoading(true);
    const callingCode = parsePhoneNumber(values.mobileNumber);
    let countrycodeis = callingCode.countryCallingCode;
    const newphoneNumber = values.mobileNumber
      .replace(countrycodeis, "")
      .replace(/^(\+)+/, "")
      .trim();

    const formValues = {
      first_name: values.firstName,
      last_name: values.lastName,
      email: values.email,
      phone_number: `${newphoneNumber}`,
      country_code: `+${countrycodeis}`,
      description: values.comments,
      possess_jet: values.possess_jet,
    };

    if (values.possess_jet === "yes") {
      formValues.aircraft_name = values.aircraft_name;
      formValues.aircraft_category = values.aircraft_category;
      formValues.highlights = values.highlights;
      formValues.airframe = values.airframe;
      formValues.aircraft_service_changes = values.aircraftService;
      formValues.interior = values.interior;
      formValues.floor_plan = values.floorPlan;
      formValues.images = values.images;
      formValues.maintenance = values.maintenance;
      formValues.engine_detail_pdf = values.engine_detail_pdf;
      formValues.apu_pdf = values.apu_pdf;
      formValues.avionics = values.avionics_pdf;
    }

    // console.log(formValues);

    try {
      const { data } = await axios.post("/buyJet", formValues);
      // console.log("data is--> ", data);
      setUserDetails({
        firstName: "",
        lastName: "",
        email: "",
        mobileNumber: "",
        comments: "",
        highlights: "",
        airframe: "",
        aircraftService: "",
        interior: "",
        floorPlan: "",
        images: [],
        engine_detail_pdf: "",
        apu_pdf: "",
        avionics_pdf: "",
        aircraft_name: "",
        aircraft_category: "",
        maintenance: "",
        possess_jet: "no",
      });

      if (data.message === "OTP Sent Successfully") {
        navigate("/otp", { state: formValues.email });
        toast.success("Otp Sent Successfully");
      } else {
        navigate("/thanku");
        toast.success("Form Submit Successfully");
      }
      setIsLoading(false);

      toast.success("Your From is Submitted successfully");
    } catch (error) {
      setIsLoading(false);

      console.error(error);
      if (error?.response?.data?.errors) {
        toast.error(`${error.response.data.errors[0].msg}`, {
          position: toast.POSITION.TOP_RIGHT,
        });
      } else {
        toast.error(`${error?.response?.data?.message}`, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    }
  };
  return (
    <>
      <div className="formbody">
        <div className="text-center mb-14">
          <h5>Ready To Buy?</h5>
          <p className="pt-5">
            Share necessary details for a comprehensive understanding, we'll
            respond accordingly.
          </p>
        </div>
        <div>
          <Formik
            enableReinitialize
            initialValues={userDetails}
            validate={(values) => buyform(values, checkbox)}
            validateOnChange
            onSubmit={(values) => {
              handlesubmit(values);
            }}
          >
            {(formikBag) => (
              <Form>
                {/* {console.log(formikBag)} */}
                <div>
                  <div className="d-flex">
                    <div className="md:flex">
                      <div className="basis-1/2 px-4 mb-5">
                        <div className="form-field">
                          <input
                            type="text"
                            name="firstName"
                            className="text-capitalize"
                            placeholder="First Name*"
                            value={formikBag.values.firstName}
                            onChange={(e) => {
                              formikBag.setFieldValue(
                                "firstName",
                                e.target.value
                              );
                            }}
                          />
                        </div>
                        <ErrorMessage
                          name="firstName"
                          component="div"
                          className="errormessage"
                        />
                      </div>
                      <div className="basis-1/2 px-4 mb-5">
                        <div className="form-field">
                          <input
                            type="text"
                            name="lastName"
                            className="text-capitalize"
                            placeholder="Last Name*"
                            value={formikBag.values.lastName}
                            onChange={(e) => {
                              formikBag.setFieldValue(
                                "lastName",
                                e.target.value
                              );
                            }}
                          />
                        </div>
                        <ErrorMessage
                          name="lastName"
                          component="div"
                          className="errormessage"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="px-4 mb-5">
                    <div className="form-field flex items-end">
                      <textarea
                        name="comments"
                        placeholder="Specify Your Inquiry"
                        value={formikBag.values.comments}
                        onChange={(e) => {
                          const inputValue = e.target.value;
                          if (inputValue.length <= 250) {
                            formikBag.setFieldValue("comments", inputValue);
                          }
                        }}
                      ></textarea>
                      <div className="text-white ps-4">
                        {formikBag.values.comments.length}/250
                      </div>
                    </div>
                    <ErrorMessage
                      name="comments"
                      component="div"
                      className="errormessage"
                    />
                  </div>
                  <div className="md:flex">
                    <div className=" basis-1/2 px-4 mb-5">
                      <div className="form-field">
                        <PhoneInput
                          international
                          defaultCountry="US"
                          name="mobileNumber"
                          className="w-100"
                          placeholder="Enter phone number"
                          value={formikBag?.values?.mobileNumber}
                          onChange={(value) => {
                            formikBag.setFieldValue("mobileNumber", value);
                          }}
                          error={
                            formikBag.touched.mobileNumber &&
                            formikBag.errors.mobileNumber
                              ? formikBag.errors.mobileNumber
                              : null
                          }
                        />
                      </div>
                      <p className="errormessage">
                        {formikBag.errors.mobileNumber}
                      </p>
                    </div>
                    <div className="basis-1/2 px-4 mb-5">
                      <div className="form-field">
                        <input
                          type="email"
                          name="email"
                          placeholder="Email Address*"
                          value={formikBag.values.email}
                          onChange={(e) => {
                            formikBag.setFieldValue("email", e.target.value);
                          }}
                        />
                      </div>
                      <ErrorMessage
                        name="email"
                        component="div"
                        className="errormessage"
                      />
                    </div>
                  </div>
                  <div className="md:flex px-4 my-4">
                    <div className="flex items-center basis-1/2">
                      <div for="myCheckbox01" class="checkbox">
                        <input
                          class="checkbox__input"
                          type="checkbox"
                          id="myCheckbox01"
                          onClick={() => handleCheckbox({ formikBag })}
                          onChange={(e) => {
                            formikBag.setFieldValue(
                              "possess_jet",
                              e.target.checked ? "yes" : "no"
                            );
                          }}
                        />
                        <svg
                          class="checkbox__icon"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 22 22"
                        >
                          <rect
                            width="21"
                            height="21"
                            x=".5"
                            y=".5"
                            fill="#FFF"
                            stroke="#F37335"
                            rx="3"
                          />
                          <path
                            class="tick"
                            stroke="#000"
                            fill="none"
                            stroke-linecap="round"
                            stroke-width="4"
                            d="M4 10l5 5 9-9"
                          />
                        </svg>
                      </div>
                      <p className="ps-4">Do you currently possess a jet?</p>
                    </div>
                    {checkbox ? (
                      <div className="basis-1/2 flex px-4 my-4">
                        <div className="flex flex-wrap">
                          <p>Required Maintenance?</p>
                          <div className="flex">
                            <div className="mx-5">
                              <input
                                type="radio"
                                id="Yes"
                                name="maintenance"
                                value={true}
                                checked={formikBag.values.maintenance === true}
                                onChange={() => {
                                  formikBag.setFieldValue("maintenance", true);
                                }}
                              />
                              <label htmlFor="Yes" className="ps-3">
                                Yes
                              </label>
                            </div>
                            <div className="mx-5">
                              <input
                                type="radio"
                                id="No"
                                name="maintenance"
                                value={false}
                                checked={formikBag.values.maintenance === false}
                                onChange={() => {
                                  formikBag.setFieldValue("maintenance", false);
                                }}
                              />
                              <label htmlFor="No" className="ps-3">
                                No
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : null}
                  </div>
                </div>

                {checkbox ? (
                  <div className="hiddenpart">
                    <div className="d-flex">
                      <div className="md:flex">
                        <div className="basis-1/2 px-4 mb-5">
                          <div className="form-field">
                            <input
                              type="text"
                              name="aircraft_name"
                              className="text-capitalize"
                              placeholder="Aircraft Name*"
                              value={formikBag.values.aircraft_name}
                              onChange={(e) => {
                                formikBag.setFieldValue(
                                  "aircraft_name",
                                  e.target.value
                                );
                              }}
                            />
                          </div>
                          <ErrorMessage
                            name="aircraft_name"
                            component="div"
                            className="errormessage"
                          />
                        </div>
                        <div className="basis-1/2 px-4 mb-5">
                          <div className="form-field">
                            <input
                              type="text"
                              name="aircraft_category"
                              className="text-capitalize"
                              placeholder="Category of Aircraft*"
                              value={formikBag.values.aircraft_category}
                              onChange={(e) => {
                                formikBag.setFieldValue(
                                  "aircraft_category",
                                  e.target.value
                                );
                              }}
                            />
                          </div>
                          <ErrorMessage
                            name="aircraft_category"
                            component="div"
                            className="errormessage"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="px-4 imguploadcont py-3">
                      <div className="heading ">
                        <h2 className="py-3 text-left">Attach Images</h2>
                      </div>
                      <ImageUpload formikBag={formikBag} />
                    </div>
                    <Document formikBag={formikBag} />
                    <div className="d-flex">
                      <div className="md:flex">
                        <div className="basis-1/2 px-4 mb-5">
                          <div className="form-field">
                            <input
                              type="text"
                              name="highlights"
                              className="text-capitalize"
                              placeholder="Highlights*"
                              value={formikBag.values.highlights}
                              onChange={(e) => {
                                formikBag.setFieldValue(
                                  "highlights",
                                  e.target.value
                                );
                              }}
                            />
                          </div>
                          <ErrorMessage
                            name="highlights"
                            component="div"
                            className="errormessage"
                          />
                        </div>
                        <div className="basis-1/2 px-4 mb-5">
                          <div className="form-field">
                            <input
                              type="text"
                              name="airframe"
                              className="text-capitalize"
                              placeholder="Airframe*"
                              value={formikBag.values.airframe}
                              onChange={(e) => {
                                formikBag.setFieldValue(
                                  "airframe",
                                  e.target.value
                                );
                              }}
                            />
                          </div>
                          <ErrorMessage
                            name="airframe"
                            component="div"
                            className="errormessage"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="d-flex">
                      <div className="md:flex">
                        <div className="basis-1/2 px-4 mb-5">
                          <div className="form-field">
                            <input
                              type="text"
                              name="aircraftService"
                              className="text-capitalize"
                              placeholder="Aircraft Service Changes*"
                              value={formikBag.values.aircraftService}
                              onChange={(e) => {
                                formikBag.setFieldValue(
                                  "aircraftService",
                                  e.target.value
                                );
                              }}
                            />
                          </div>
                          <ErrorMessage
                            name="aircraftService"
                            component="div"
                            className="errormessage"
                          />
                        </div>
                        <div className="basis-1/2 px-4 mb-5">
                          <div className="form-field">
                            <input
                              type="text"
                              name="interior"
                              className="text-capitalize"
                              placeholder="Interior*"
                              value={formikBag.values.interior}
                              onChange={(e) => {
                                formikBag.setFieldValue(
                                  "interior",
                                  e.target.value
                                );
                              }}
                            />
                          </div>
                          <ErrorMessage
                            name="interior"
                            component="div"
                            className="errormessage"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="d-flex">
                      <div className="md:flex">
                        <div className="basis-1/2 px-4 mb-5">
                          <div className="form-field">
                            <input
                              type="text"
                              name="floorPlan"
                              className="text-capitalize"
                              placeholder="Floor Plan*"
                              value={formikBag.values.floorPlan}
                              onChange={(e) => {
                                formikBag.setFieldValue(
                                  "floorPlan",
                                  e.target.value
                                );
                              }}
                            />
                          </div>
                          <ErrorMessage
                            name="floorPlan"
                            component="div"
                            className="errormessage"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                ) : null}

                <div className="text-center button1 mt-10">
                  <button type="submit">make an Inquiry</button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
      {isLoading && <Overlay />}
    </>
  );
};

const ImageUpload = ({ formikBag }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [imgData, setImgData] = useState([]);
  const hiddenFileInput = useRef(null);

  const handleClick = () => {
    hiddenFileInput.current.click();
  };

  const handleFileChange = async (e) => {
    setIsLoading(true);

    const files = e.target.files;
    const uploadedImages = await Promise.all(
      Array.from(files).map(async (file) => {
        const image = await uploadImage(file);
        return image?.url;
      })
    );

    setImgData((prevImgData) => [...prevImgData, ...uploadedImages]);

    setIsLoading(false);
    formikBag.setFieldValue("images", [...imgData, ...uploadedImages]);
  };

  const removeImg = (index) => {
    const updatedImgData = [...imgData];
    updatedImgData.splice(index, 1);
    setImgData(updatedImgData);
    setImgData((prevImgData) => {
      formikBag.setFieldValue("images", prevImgData);
      return prevImgData;
    });
  };

  return (
    <>
      <div className="flex items-center">
        <div className="uplaodImages">
          {imgData.map((item, index) => {
            return (
              <>
                <div className="imagess">
                  <div>
                    <img src={item} alt="" />
                  </div>
                  <div className="removebtm">
                    <img
                      src={deleter}
                      className="cursor-pointer"
                      alt=""
                      onClick={() => removeImg(index)}
                    />
                  </div>
                </div>
              </>
            );
          })}
          <div className="img-wrap" onClick={handleClick}>
            <div>
              <img src={plus} alt="" />
            </div>
          </div>
        </div>
        {/* <div className="img-wrap" onClick={handleClick}>
          <div>
            <img src={plus} alt="" />
          </div>
        </div> */}
        <input
          type="file"
          ref={hiddenFileInput}
          style={{ display: "none" }}
          onChange={handleFileChange}
          multiple
          accept="image/*"
        />
      </div>
      {isLoading && <Overlay />}
    </>
  );
};

const Document = ({ formikBag }) => {
  const [pdf1, setPdf1] = useState();
  const [pdf2, setPdf2] = useState();
  const [pdf3, setPdf3] = useState();
  const [pdf1Name, setPdf1Name] = useState();
  const [pdf2Name, setPdf2Name] = useState();
  const [pdf3Name, setPdf3Name] = useState();

  // console.log(pdf1);
  // console.log(pdf1);

  const hiddenFileInput1 = useRef(null);
  const hiddenFileInput2 = useRef(null);
  const hiddenFileInput3 = useRef(null);

  const handleClick1 = () => {
    hiddenFileInput1.current.click();
  };
  const handleClick2 = () => {
    hiddenFileInput2.current.click();
  };
  const handleClick3 = () => {
    hiddenFileInput3.current.click();
  };

  const remove1 = () => {
    setPdf1(null);
    formikBag.setFieldValue("engine_detail_pdf", null);
  };

  const remove2 = () => {
    setPdf2(null);
    formikBag.setFieldValue("apu_pdf", null);
  };
  const remove3 = () => {
    setPdf3(null);
    formikBag.setFieldValue("avionics_pdf", null);
  };

  const handleFileChange1 = async (e) => {
    const file = e.target.files[0];

    if (file) {
      const uploadedPdf = await uploadPdf(file);
      setPdf1(uploadedPdf?.url);
      setPdf1Name(uploadedPdf?.fileName);
      formikBag.setFieldValue("engine_detail_pdf", uploadedPdf?.url);
    }
  };
  const handleFileChange2 = async (e) => {
    const file = e.target.files[0];

    if (file) {
      const uploadedPdf = await uploadPdf(file);
      setPdf2(uploadedPdf?.url);
      setPdf2Name(uploadedPdf?.fileName);
      formikBag.setFieldValue("apu_pdf", uploadedPdf?.url);
    }
  };
  const handleFileChange3 = async (e) => {
    const file = e.target.files[0];

    if (file) {
      const uploadedPdf = await uploadPdf(file);
      setPdf3(uploadedPdf?.url);
      setPdf3Name(uploadedPdf?.fileName);
      formikBag.setFieldValue("avionics_pdf", uploadedPdf?.url);
    }
  };

  return (
    <>
      <div className="md:flex px-3 py-4 my-10 pdfcont">
        <div className="basis-1/3 my-4">
          <div className="headings">
            <h2>Engine Details*</h2>
          </div>

          {pdf1 == null ? (
            <div className="text-center">
              <div className="pdf-wrap text-center" onClick={handleClick1}>
                <div>Attach File</div>
              </div>
            </div>
          ) : (
            <div className="pdformate flex items-center px-3 py-2">
              <img src={pdfimg} alt="" className="me-4" />
              <Link to={pdf1} target="_blank">
                <p>{pdf1Name}</p>
              </Link>
              <div className="cross ms-5">
                <img src={deleteimg} alt="" onClick={() => remove1()} />
              </div>
            </div>
          )}

          <input
            type="file"
            ref={hiddenFileInput1}
            style={{ display: "none" }}
            onChange={handleFileChange1}
            accept="application/pdf"
          />
        </div>
        <div className="basis-1/3 my-4">
          <div className="headings">
            <h2>APU*</h2>
          </div>
          {pdf2 == null ? (
            <div className="pdf-wrap" onClick={handleClick2}>
              <div>Attach File</div>
            </div>
          ) : (
            <div className="pdformate flex items-center px-3 py-2">
              <img src={pdfimg} alt="" className="me-4" />
              <Link to={pdf2} target="_blank">
                <p>{pdf2Name}</p>
              </Link>

              <div className="cross ms-5">
                <img src={deleteimg} alt="" onClick={() => remove2()} />
              </div>
            </div>
          )}

          <input
            type="file"
            ref={hiddenFileInput2}
            style={{ display: "none" }}
            onChange={handleFileChange2}
            accept="application/pdf"
          />
        </div>
        <div className="basis-1/3 my-4">
          <div className="headings">
            <h2>Avionics*</h2>
          </div>
          {pdf3 == null ? (
            <div className="pdf-wrap" onClick={handleClick3}>
              <div>Attach File</div>
            </div>
          ) : (
            <div className="pdformate flex items-center px-3 py-2">
              <img src={pdfimg} alt="" className="me-4" />
              <Link to={pdf3} target="_blank">
                <p>{pdf3Name}</p>
              </Link>
              <div className="cross ms-5">
                <img src={deleteimg} alt="" onClick={() => remove3()} />
              </div>
            </div>
          )}

          <input
            type="file"
            ref={hiddenFileInput3}
            style={{ display: "none" }}
            onChange={handleFileChange3}
            accept="application/pdf"
          />
        </div>
      </div>
    </>
  );
};

const uploadImage = async (file) => {
  var formData = new FormData();
  formData.append("file", file);
  formData.append("type", "1");

  try {
    const { data } = await axios.post("/upload-file", formData);
    return data;
  } catch (error) {
    console.error("Error uploading image:", error);
  }
};

const uploadPdf = async (file) => {
  var formData = new FormData();
  formData.append("file", file);
  formData.append("type", "4");

  try {
    const { data } = await axios.post("/upload-file", formData);
    return data;
  } catch (error) {
    console.error("Error uploading pdf:", error);
  }
};
