import React, { useEffect } from "react";
import "./style.css";

import flight from "../../assets/images/tour/icon/flight.svg";
import hotel from "../../assets/images/tour/icon/hotel.svg";
import block from "../../assets/images/tour/icon/block.svg";
import car from "../../assets/images/tour/icon/car.svg";

import TourInquiryForm from "../TourInquiry/TourInquiry";
import { useLocation, useNavigate } from "react-router-dom";

export default function TourDeatils() {
  const naviagte = useNavigate();
  const location = useLocation();

  const { state } = location;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <section className="tourdetails">
        <div className="heading">
          <h2>
            {state.tour_title} , {state?.pickup_vehicle}
          </h2>
        </div>
        <div className="md:flex bannners">
          <div className="basis-1/2 sm:pe-4 gallery">
            <div
              onClick={() =>
                naviagte(`/Tour-images/${state?.id}`, { state: state })
              }
              className="obsimg cursor-pointer"
            >
              +5 More
            </div>
            {state.images.slice(0, 5).map((item, index) => (
              <div key={index} className={`item${index + 1}`}>
                <img src={item} alt="" />
              </div>
            ))}
          </div>
          <div className="basis-1/2 sm:ps-6 md:pt-0 pt-6 textsection">
            <div>
              <div
                dangerouslySetInnerHTML={{
                  __html: state?.long_description,
                }}
              ></div>
              <div className="flex justify-start">
                <TourInquiryForm title="make an inquiry" />
              </div>
            </div>
          </div>
        </div>
        <div className="inficon">
          <div className="flex justify-center flex-wrap">
            <div className="infocards">
              <img src={flight} alt="" className="imgs" />
              <div className="cont">
                <h6>private jet</h6>
              </div>
            </div>
            <div className="infocards">
              <img src={hotel} alt="" className="imgs" />
              <div className="cont">
                <h6>Hotel</h6>
              </div>
            </div>
            <div className="infocards">
              <img src={block} alt="" className="imgs" />
              <div className="cont">
                <h6>Activities</h6>
              </div>
            </div>
            <div className="infocards">
              <img src={car} alt="" className="imgs" />
              <div className="cont">
                <h6 className="text-center">Pickup Vehicle</h6>
              </div>
            </div>
          </div>
        </div>
        <div className="md:flex bannnerstwo">
          <div className="basis-1/2 pe-6 textsection">
            <div>
              <h2>ABOUT THE TRIP</h2>
              <p>{state?.about_trip}</p>
            </div>
            <div>
              <h2 className="mt-8">WHAT'S INCLUDED IN THE PRICE</h2>
              <div
                dangerouslySetInnerHTML={{
                  __html: state?.what_include,
                }}
              ></div>
            </div>
          </div>
          <div className="basis-1/2 md:pt-0 pt-5 ps-4 gallery">
            {state.images.slice(0, 6).map((item, index) => (
              <>
                <div key={index} className={`item${index + 1}`}>
                  <img src={item} alt="" />
                </div>
              </>
            ))}
          </div>
        </div>
        <div className="pb-12">
          <TourInquiryForm title="make an inquiry" />
        </div>
      </section>
    </>
  );
}
