// const NewpasswordRegex = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}$/;
// const NewpasswordRegex = /^.{8,}$/;

// global regex
// const noHtmlRegex = /<\/?[^>]+(>|$)/g;
// const onlyAlphbetRegex = /^[a-zA-Z ]*$/;
// const numberOnly = /^\d+$/;
// var phoneRegex = /^[0-9]+$/;

export const contactus = (values) => {
  let errors = {};

  if (!values.email) {
    errors.email = "Please enter the Email";
  } else if (!/^[\w.-]+@[\w.-]+\.[a-zA-Z]{2,4}$/.test(values.email)) {
    errors.email = "Enter a valid email address";
  }

  if (!values.firstName) {
    errors.firstName = "Please enter your first name";
  } else if (!/^[A-Za-z\s'-]+$/.test(values.firstName)) {
    errors.firstName = "Enter Valid first name";
  }
  if (!values.lastName) {
    errors.lastName = "Please enter your last name";
  } else if (!/^[A-Za-z\s'-]+$/.test(values.lastName)) {
    errors.lastName = "Enter Valid last name";
  }

  if (!values.mobileNumber) {
    errors.mobileNumber = "Please Enter mobile number";
  } else if (values.mobileNumber.length < 6) {
    errors.mobileNumber = "Your mobile should contain atleast 4 digits";
  } else if (values.mobileNumber.length > 17) {
    errors.mobileNumber = "Your mobile should not greater than 16 digits ";
  }

  if (!values.comments) {
    errors.comments = "Please write a comment";
  } else if (values.comments.length > 250) {
    errors.comments = "Comments must be within a 250-character limit";
  }

  return errors;
};

export const otpValidator = (values) => {
  let errors = {};

  if (values.verificationCode.length < 4) {
    errors.verificationCode = "Enter valid OTP";
  }
  if (!values.verificationCode) {
    errors.verificationCode = "Please enter OTP";
  }
  return errors;
};

export const buyform = (values, checkbox) => {
  // console.log(checkbox);
  let errors = {};

  if (!values.email) {
    errors.email = "Please enter the Email";
  } else if (!/^[\w.-]+@[\w.-]+\.[a-zA-Z]{2,4}$/.test(values.email)) {
    errors.email = "Enter a valid email address";
  }

  if (!values.firstName) {
    errors.firstName = "Please enter your first name";
  } else if (!/^[A-Za-z\s'-]+$/.test(values.firstName)) {
    errors.firstName = "Enter Valid first name";
  }
  if (!values.lastName) {
    errors.lastName = "Please enter your last name";
  } else if (!/^[A-Za-z\s'-]+$/.test(values.lastName)) {
    errors.lastName = "Enter Valid last name";
  }

  if (!values.mobileNumber) {
    errors.mobileNumber = "Please Enter mobile number";
  } else if (values.mobileNumber.length < 6) {
    errors.mobileNumber = "Your mobile should contain atleast 4 digits";
  } else if (values.mobileNumber.length > 17) {
    errors.mobileNumber = "Your mobile should not greater than 16 digits ";
  }

  if (!values.comments) {
    errors.comments = "Please write a comment";
  } else if (values.comments.length > 250) {
    errors.comments = "Comments must be within a 250-character limit";
  }

  if (checkbox === true) {
    if (!values.highlights) {
      errors.highlights = "Please Write a highlights";
    }
    if (!values.airframe) {
      errors.airframe = "Please Write a Airframe";
    }
    if (!values.aircraftService) {
      errors.aircraftService = "Please Write a Aircraft Service Changes";
    }
    if (!values.interior) {
      errors.interior = "Please Write a Interior";
    }
    if (!values.floorPlan) {
      errors.floorPlan = "Please Write a FloorPlan";
    }
    if (!values.aircraft_name) {
      errors.aircraft_name = "Please Write a aircraft_name";
    }
    if (!values.aircraft_category) {
      errors.aircraft_category = "Please Write a aircraft_category";
    }
  }

  return errors;
};

export const sellform = (values) => {
  let errors = {};

  if (!values.email) {
    errors.email = "Please enter the Email";
  } else if (!/^[\w.-]+@[\w.-]+\.[a-zA-Z]{2,4}$/.test(values.email)) {
    errors.email = "Enter a valid email address";
  }

  if (!values.firstName) {
    errors.firstName = "Please enter your first name";
  } else if (!/^[A-Za-z\s'-]+$/.test(values.firstName)) {
    errors.firstName = "Enter Valid first name";
  }
  if (!values.lastName) {
    errors.lastName = "Please enter your last name";
  } else if (!/^[A-Za-z\s'-]+$/.test(values.lastName)) {
    errors.lastName = "Enter Valid last name";
  }

  if (!values.mobileNumber) {
    errors.mobileNumber = "Please Enter mobile number";
  } else if (values.mobileNumber.length < 6) {
    errors.mobileNumber = "Your mobile should contain atleast 4 digits";
  } else if (values.mobileNumber.length > 17) {
    errors.mobileNumber = "Your mobile should not greater than 16 digits ";
  }

  if (!values.comments) {
    errors.comments = "Please write a comment";
  } else if (values.comments.length > 250) {
    errors.comments = "Comments must be within a 250-character limit";
  }

  if (!values.highlights) {
    errors.highlights = "Please Write a highlights";
  }
  if (!values.airframe) {
    errors.airframe = "Please Write a Airframe";
  }
  if (!values.aircraftService) {
    errors.aircraftService = "Please Write a Aircraft Service Changes";
  }
  if (!values.interior) {
    errors.interior = "Please Write a Interior";
  }
  if (!values.floorPlan) {
    errors.floorPlan = "Please Write a FloorPlan";
  }
  if (!values.aircraft_name) {
    errors.aircraft_name = "Please Write a aircraft_name";
  }
  if (!values.aircraft_category) {
    errors.aircraft_category = "Please Write a aircraft_category";
  }

  if (!values.range) {
    errors.range = "Please Write a range";
  }
  if (!values.fuel) {
    errors.fuel = "Please Write a fuel";
  }
  if (!values.passenger) {
    errors.passenger = "Please Write a passenger";
  }

  return errors;
};

export const tour_enquiry = (values) => {
  let errors = {};

  if (!values.email) {
    errors.email = "Please enter the Email";
  } else if (!/^[\w.-]+@[\w.-]+\.[a-zA-Z]{2,4}$/.test(values.email)) {
    errors.email = "Enter a valid email address";
  }

  if (!values.firstName) {
    errors.firstName = "Please enter your first name";
  } else if (!/^[A-Za-z\s'-]+$/.test(values.firstName)) {
    errors.firstName = "Enter Valid first name";
  }
  if (!values.lastName) {
    errors.lastName = "Please enter your last name";
  } else if (!/^[A-Za-z\s'-]+$/.test(values.lastName)) {
    errors.lastName = "Enter Valid last name";
  }
  if (!values.mobileNumber) {
    errors.mobileNumber = "Please Enter mobile number";
  } else if (values.mobileNumber.length < 6) {
    errors.mobileNumber = "Your mobile should contain atleast 4 digits";
  } else if (values.mobileNumber.length > 17) {
    errors.mobileNumber = "Your mobile should not greater than 16 digits ";
  }
  if (!values.comments) {
    errors.comments = "Please write a comment";
  } else if (values.comments.length > 250) {
    errors.comments = "Comments must be within a 250-character limit";
  }
  if (!values.jet_type) {
    errors.jet_type = "Please select a jet_type";
  }
  if (!values.tour_title) {
    errors.tour_title = "Please select a tour_title";
  }
  if (!values.pickup_vehicle) {
    errors.pickup_vehicle = "Please select a pickup_vehicle";
  }
  // if (!values.pickup_location) {
  //   errors.pickup_location = "Please Write a pickup_location";
  // }
  return errors;
};
