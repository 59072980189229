import React, { useEffect } from "react";
import "./style.css";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import pdfimg from "../../assets/images/pdf.svg";
import downloadimg from "../../assets/images/download.svg";
import { Link, useLocation } from "react-router-dom";
import EnquiryForm from "../../component/enquiryform/EnquiryForm";
import fuel from "../../assets/images/img/fuel.svg";
import range from "../../assets/images/img/range.svg";
import passenger from "../../assets/images/img/passenger.svg";

const ClientJetDetails = () => {
  const location = useLocation();

  const { state } = location;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const settings = {
    autoplay: false,
    dots: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    speed: 5000,
    autoplaySpeed: 100,
    pauseOnHover: true,
  };
  return (
    <>
      <section className="clientJetDetails">
        <div className="cjbanner">
          <Slider {...settings} className="slider1">
            {state?.item?.images
              ? state.item.images.map((item, index) => (
                  <div key={index} className="slider-item">
                    <img src={item} alt={`Slide ${index + 1}`} />
                  </div>
                ))
              : null}
          </Slider>
        </div>
        <div className="detailsjet">
          <div className="heads">
            <h2>{state?.item?.aircraft_name}</h2>
            <div className="line md:block hidden my-4"></div>
            <div>
              <p>{state?.item?.aircraft_category}</p>
            </div>
          </div>
          <div className="dcr mt-6">
            <p>{state?.item?.description}</p>
          </div>
          <div className="hightight">
            <div className="lg:flex items-center">
              <div className="basis-1/2">
                <div className="heading flex justify-start mt-8 mb-6">
                  <h2 className="text-left">Highlights</h2>
                </div>
                <p>{state?.item?.highlights}</p>
              </div>
              <div className="basis-1/2 my-5">
                <div className="inficon">
                  <div className="flex flex-wrap justify-center">
                    <div className="infocards">
                      <img src={fuel} alt="" className="imgs" />
                      <div className="cont">
                        <h6>{state?.item?.fuel} lbs</h6>
                      </div>
                    </div>
                    <div className="infocards">
                      <img src={range} alt="" className="imgs" />
                      <div className="cont">
                        <h6 className="uppercase">{state?.item?.range} nm</h6>
                      </div>
                    </div>
                    <div className="infocards">
                      <img src={passenger} alt="" className="imgs" />
                      <div className="cont">
                        <h6>{state?.item?.passenger} Passenger</h6>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="pdf mt-6">
            <div className="md:flex">
              <div className="basis-1/3 mb-4">
                <h4>Engine Details*</h4>

                <div className="pdformate flex items-center px-3 py-2">
                  <img src={pdfimg} alt="" className="me-4" />
                  <Link
                    to={state?.item?.engine_detail_pdf}
                    target="_blank"
                    download={state?.item?.engine_detail_pdf}
                    className="flex items-center"
                  >
                    <p>Engine Details</p>
                    <img src={downloadimg} alt="" className="ms-4" />
                  </Link>
                </div>
              </div>
              <div className="basis-1/3 mb-4">
                <h4>APU*</h4>
                <div className="pdformate flex items-center px-3 py-2">
                  <img src={pdfimg} alt="" className="me-4" />
                  <Link
                    to={state?.item?.apu_pdf}
                    target="_blank"
                    className="flex items-center"
                  >
                    <p>APU</p>
                    <img src={downloadimg} alt="" className="ms-4" />
                  </Link>
                </div>
              </div>
              <div className="basis-1/3">
                <h4>Avionics*</h4>
                <div className="pdformate flex items-center px-3 py-2">
                  <img src={pdfimg} alt="" className="me-4" />
                  <Link
                    to={state?.item?.avionics}
                    target="_blank"
                    className="flex items-center"
                  >
                    <p>Avionics</p>
                    <img src={downloadimg} alt="" className="ms-4" />
                  </Link>
                </div>
              </div>
            </div>
          </div>

          <div className="othr mt-8">
            <div className="md:flex flex-wrap items-center">
              <div className="basis-1/2 pe-6">
                <div className="cont">
                  <p>{state?.item?.airframe}</p>
                </div>
              </div>
              <div className="basis-1/2 pe-6">
                <div className="cont">
                  <p>{state?.item?.aircraft_service_changes}</p>
                </div>
              </div>
              <div className="basis-1/2 pe-6">
                <div className="cont">
                  <p>{state?.item?.interior}</p>
                </div>
              </div>
              <div className="basis-1/2 ">
                <div className="cont">
                  <p>{state?.item?.floor_plan}</p>
                </div>
              </div>
            </div>
          </div>

          <EnquiryForm title="make an inquiry" />
        </div>
      </section>
    </>
  );
};

export default ClientJetDetails;
