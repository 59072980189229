import React, { useEffect } from "react";
import bg from "../../assets/images/deal/bg.png";
import "./style.css";
import Slider from "react-slick";

function Commericaljetpage() {
  useEffect(() => {
    window.scrollTo(0, 0);
  });
  return (
    <>
      <section className="dealwith">
        <div className="banner">
          <div>
            <img src={bg} alt="" />
            <div className="scroll-down">
              <div className="point"></div>
            </div>
          </div>
        </div>
        <CommercialJet />
        <Content />
      </section>
    </>
  );
}

export default Commericaljetpage;

const CommercialJet = () => {
  const settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const data = [
    {
      img: require("../../assets/images/deal/p1.png"),
      title: `2000 Gulfstream G200`,
      range: "7500 NM",
      passengers: 16,
    },
    {
      img: require("../../assets/images/deal/p2.png"),
      title: "2008 Global 5000",
      range: "6500NM",
      passengers: 14,
    },
    {
      img: require("../../assets/images/deal/p3.png"),
      title: "2000 Gulfstream G550",
      range: "6500NM",
      passengers: 14,
    },
    {
      img: require("../../assets/images/deal/pr1.png"),
      title: `2000 Gulfstream G200`,
      range: "7500 NM",
      passengers: 16,
    },
    {
      img: require("../../assets/images/deal/pr2.png"),
      title: "2008 Global 5000",
      range: "6500NM",
      passengers: 14,
    },
    {
      img: require("../../assets/images/deal/pr3.png"),
      title: "2000 Gulfstream G550",
      range: "6500NM",
      passengers: 14,
    },
  ];
  return (
    <>
      <div className="jetcont">
        <div className="heading">
          <h2>Commercial Jets</h2>
          <p className="pt-4">
            When we ( Seven Air Aviation) say commercial aviation; is by
            offering world-class solutions in commercial jet acquisition. With a
            passion for excellence and a commitment to innovation, we empower
            airlines and businesses to soar to new heights with the latest and
            most advanced commercial jets.
          </p>
        </div>
        <div className="slider">
          <Slider {...settings}>
            {data.map((item, index) => {
              return (
                <>
                  <div className="cards" key={index}>
                    <div className="flex items-center justify-center flex-col">
                      <div>
                        <div>
                          <img src={item.img} alt="" />
                        </div>
                        <div className="pt-4">
                          <p className="title">{item.title}</p>
                          <h6>{item.range}</h6>
                          <h6>{item.passengers}</h6>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              );
            })}
          </Slider>
        </div>
      </div>
    </>
  );
};

const Content = () => {
  const img = [
    require("../../assets/images/deal/commerical/img1.png"),
    require("../../assets/images/deal/commerical/img2.jpg"),
    require("../../assets/images/deal/commerical/img3.png"),
    require("../../assets/images/deal/commerical/img4.png"),
    require("../../assets/images/deal/commerical/img5.jpg"),
    require("../../assets/images/deal/commerical/img6.jpg"),
    require("../../assets/images/deal/commerical/img7.jpg"),
    require("../../assets/images/deal/commerical/img8.png"),
  ];
  return (
    <>
      <div className="Content">
        <h3 className="pb-4">
          Akwaaba! to Seven Air Aviation - Elevating Global Aviation
        </h3>
        <p>
          When we ( Seven Air Aviation) say commercial aviation; is by offering
          world-class solutions in commercial jet acquisition. With a passion
          for excellence and a commitment to innovation, we empower airlines and
          businesses to soar to new heights with the latest and most advanced
          commercial jets.
        </p>

        <div className="flex lg:flex-row flex-col-reverse items-center my-16">
          <div className="basis-1/2">
            <h4>Our Services</h4>
            <h5>Cutting-Edge Commercial Jet Acquisition</h5>
            <p>
              Experience the epitome of modern aviation with our cutting-edge
              commercial jet acquisition services. Whether you are a growing
              airline expanding your fleet or a business seeking efficient air
              travel solutions, we specialize in sourcing and acquiring the most
              advanced and technologically superior commercial jets available in
              the global market.
            </p>
            <h5>Tailored Solutions for Your Success</h5>
            <p>
              We understand that each client has unique requirements and goals.
              Our team of seasoned professionals works closely with you to
              tailor acquisition solutions that align with your operational
              needs, budget, and growth plans. From fuel-efficient models to
              long-range capabilities, we ensure that your commercial fleet is
              optimized for success.
            </p>
          </div>
          <div className="basis-1/2">
            <div className="flex flex-wrap imgcont">
              {img.slice(0, 4).map((item) => {
                return (
                  <>
                    <div className="basis-1/2 p-2">
                      <img src={item} alt="img" />
                    </div>
                  </>
                );
              })}
            </div>
          </div>
        </div>

        <div className="lg:flex items-center my-16">
          <div className="basis-1/2">
            <div className="flex flex-wrap imgcont">
              {img.slice(4, 8).map((item) => {
                return (
                  <>
                    <div className="basis-1/2 p-2">
                      <img src={item} alt="img" />
                    </div>
                  </>
                );
              })}
            </div>
          </div>
          <div className="basis-1/2 px-4">
            <h4>Why Choose Seven Air Aviation ?</h4>
            <ul>
              <li>
                Industry Expertise: Benefit from our deep industry knowledge and
                insights, ensuring informed decisions in your commercial jet
                acquisition.
              </li>
              <li>
                Global Reach: With an extensive network, we have access to a
                wide range of commercial jets, enabling us to find the perfect
                fit for your fleet.
              </li>
              <li>
                Efficiency and Performance: Our focus is on acquiring aircraft
                that offer exceptional efficiency, performance, and the latest
                in aviation technology.
              </li>
              <li>
                Client-Centric Approach: Your success is our priority. We are
                dedicated to delivering personalized and client-centric
                solutions that exceed your expectations.
              </li>
              <li>
                Reliability: Count on us for reliable and transparent services.
                We prioritize open communication and integrity in all our
                transactions.
              </li>
            </ul>
          </div>
        </div>
        <h4>Contact Us</h4>
        <p>
          Ready to elevate your airline or business with a world-class
          commercial jets? Contact Seven Air Aviation today to discuss your
          acquisition needs and explore our portfolio of cutting-edge aircrafts.
        </p>
      </div>
    </>
  );
};

const Images = () => {
  const img = [
    require("../../assets/images/deal/commerical/img1.png"),
    require("../../assets/images/deal/commerical/img2.jpg"),
    require("../../assets/images/deal/commerical/img3.png"),
    require("../../assets/images/deal/commerical/img4.png"),
    require("../../assets/images/deal/commerical/img5.jpg"),
    require("../../assets/images/deal/commerical/img6.jpg"),
    require("../../assets/images/deal/commerical/img7.jpg"),
    require("../../assets/images/deal/commerical/img8.png"),
  ];
  return (
    <>
      <div className="imgcont flex justify-between items-center flex-wrap bg-black">
        {img.slice(0, 4).map((item) => {
          return (
            <>
              <div className="basis-1/4 p-2">
                <img src={item} alt="img" />
              </div>
            </>
          );
        })}
      </div>
    </>
  );
};
