import React, { useEffect } from "react";
import bg from "../../assets/images/deal/bg.png";
import "./style.css";
import Slider from "react-slick";
function Helicopterpage() {
  useEffect(() => {
    window.scrollTo(0, 0);
  });
  return (
    <>
      <section className="dealwith">
        <div className="banner">
          <div>
            <img src={bg} alt="" />
            <div className="scroll-down">
              <div className="point"></div>
            </div>
          </div>
        </div>
        <Helicopter />
        <Content />
      </section>
    </>
  );
}

export default Helicopterpage;

const Helicopter = () => {
  const settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const data = [
    {
      img: require("../../assets/images/deal/h1.png"),
      title: `2000 Gulfstream G200`,
      range: "7500 NM",
      passengers: 16,
    },
    {
      img: require("../../assets/images/deal/h2.png"),
      title: "2008 Global 5000",
      range: "6500NM",
      passengers: 14,
    },
    {
      img: require("../../assets/images/deal/h3.png"),
      title: "2000 Gulfstream G550",
      range: "6500NM",
      passengers: 14,
    },
    {
      img: require("../../assets/images/deal/h1.png"),
      title: `2000 Gulfstream G200`,
      range: "7500 NM",
      passengers: 16,
    },
    {
      img: require("../../assets/images/deal/h2.png"),
      title: "2008 Global 5000",
      range: "6500NM",
      passengers: 14,
    },
    {
      img: require("../../assets/images/deal/h3.png"),
      title: "2000 Gulfstream G550",
      range: "6500NM",
      passengers: 14,
    },
  ];
  return (
    <>
      <div className="jetcont">
        <div className="heading">
          <h2>Helicopter</h2>
          <p className="pt-4">
            We take pride in being a leading authority in helicopter
            acquisition. With a passion for aviation and a commitment to
            excellence, we offer unparalleled services to individuals,
            corporations, and organizations seeking to elevate their air
            mobility with premium helicopters.At Seven Air Aviation, we
            specialize in providing comprehensive helicopter acquisition
            solutions tailored to meet the unique needs of our clients. Whether
            you're looking for a single-engine helicopter for personal use,
            fleet for corporate operations or national fleet use, our team of
            experts is dedicated to delivering excellence.
          </p>
        </div>
        <div className="slider">
          <Slider {...settings}>
            {data.map((item, index) => {
              return (
                <>
                  <div className="cards" key={index}>
                    <div className="flex items-center justify-center flex-col">
                      <div>
                        <div>
                          <img src={item.img} alt="" />
                        </div>
                        <div className="pt-4">
                          <p className="title">{item.title}</p>
                          <h6>{item.range}</h6>
                          <h6>{item.passengers}</h6>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              );
            })}
          </Slider>
        </div>
      </div>
    </>
  );
};

const Content = () => {
  return (
    <>
      <div className="Content">
        <h4 className="pb-4">
          Explore the Skies with Seven Air Aviation - Your Premier Helicopter
          Acquisition Partner
        </h4>
        <p>
          We take pride in being a leading authority in helicopter acquisition.
          With a passion for aviation and a commitment to excellence, we offer
          unparalleled services to individuals, corporations, and organizations
          seeking to elevate their air mobility with premium helicopters.
        </p>
        <h4>Our Services</h4>

        <h5>Comprehensive Helicopter Acquisition Solutions</h5>
        <p>
          At Seven Air Aviation, we specialize in providing comprehensive
          helicopter acquisition solutions tailored to meet the unique needs of
          our clients. Whether you're looking for a single-engine helicopter for
          personal use, fleet for corporate operations or national fleet use,
          our team of experts is dedicated to delivering excellence.
        </p>
        <h5>Personalized Consultation and Sourcing</h5>
        <p>
          We understand that each client has distinct requirements. Our seasoned
          professionals work closely with you to understand your preferences,
          mission profiles, and budget constraints. Through personalized
          consultations, we source and acquire helicopters that align perfectly
          with your operational goals and expectations.
        </p>
        <h4>Why Choose Seven Air Aviation?</h4>
        <ul>
          <li>
            Expert Guidance: Rely on our team of seasoned professionals with
            in-depth knowledge of the helicopter market to guide you through the
            acquisition process.
          </li>
          <li>
            Diverse Portfolio: Explore a diverse portfolio of helicopters,
            ranging from single-engine models to multi-engine configurations,
            ensuring you find the perfect fit for your needs.
          </li>
          <li>
            Global Network: Benefit from our extensive network of industry
            connections, providing you with access to a wide range of
            helicopters from reputable manufacturers.
          </li>
          <li>
            Transparency and Integrity: We prioritize transparency and integrity
            in all our transactions. Expect clear communication and a commitment
            to ethical business practices with extreme higher standards.
          </li>
          <li>
            Tailored Solutions: Our commitment to understanding your unique
            requirements ensures that we provide solutions that are tailored to
            your specific mission and objectives.
          </li>
        </ul>
        <h4>Contact Us</h4>
        <p>
          Ready to soar to new heights? Contact Seven Air Aviation today to
          discuss your helicopter acquisition needs and explore the
          possibilities that await you in the skies.
        </p>
      </div>
    </>
  );
};
