import { useState } from "react";
import "./App.css";
import { Route, Routes, useNavigate } from "react-router-dom";
import Header from "./component/header/header";
import Banner from "./component/banner/banner";
import Sidebar from "./component/sidebar/sidebar";
import Dealwith from "./component/dealwith/dealwith";
import Unlocking from "./component/unlocking/Unlocking";
import Jets from "./component/jets/Jets";
import Compromise from "./component/Compromise/Compromise";
import Facts from "./component/facts/Facts";
import Footer from "./component/footer/Footer";
import Testimonial from "./component/testimonial/testimonial";
import Brand from "./component/brand/brand";
import AviationFuel from "./pages/AviationFuel";
import JetDetails from "./pages/JetDeatils";
import BuyForm from "./component/buyform/BuyForm";
import Thankupage from "./component/enquiryform/thankuscreen";
import OtpPage from "./component/enquiryform/otp_page";
import AboutUs from "./pages/aboutus/AboutUs";
import ClinetJet from "./pages/clinetjet/ClinetJet";
import Tour from "./pages/tour/Tour";
import TourDeatils from "./component/tourdetails/TourDeatils";
import Enquirybody from "./component/enquirybody/enquirybody";
import Contactus from "./component/enquiryform/contactus";
import SellForm from "./component/sellform/SellForm";
import Term_and_condition from "./pages/term_and_condition/Term_and_condition";
import Privacy_policy from "./pages/privacy_policy";
import TourInquiryForms from "./component/TourInquiry/tourform";
import ClientJetDetails from "./pages/clinetjetDetails";
import Tour_img from "./component/tour_images/tour_img";
import Faqbar from "./component/faq/Faq";
import Helicopterpage from "./pages/dealwith/helicopter";
import Commericaljetpage from "./pages/dealwith/commercialjetpage";
import Privatejetpage from "./pages/dealwith/privatejetpage";
import G700 from "./pages/JetDeatils/G700";
import UnlockingDetails from "./component/unlocking_details/UnlockingDeatils";

function App() {
  const navigate = useNavigate();
  const [x, setX] = useState(100);
  const handleOpen = () => {
    setX(0);
  };
  const handleClose = () => {
    setX(100);
  };

  // console.log(x);

  const enquiry = () => {
    navigate("/inquiry");
  };

  // const buyEnquiry = () => {
  //   navigate("/buy");
  // };

  const sellEnquiry = () => {
    navigate("/sell");
  };

  const tourEnquiry = () => {
    navigate("/tour-form");
  };

  return (
    <>
      <Routes>
        <Route
          path="/"
          exact
          element={
            <>
              <Header handleOpen={handleOpen} inquiry={enquiry} />
              <Sidebar handleClose={handleClose} setX={setX} x={x} />
              <Banner />
              <Dealwith />
              <Unlocking />
              <Jets />
              <Compromise />
              <Enquirybody />
              <Brand />
              <Testimonial />
              <Facts />
              <Footer />
            </>
          }
        />

        <Route
          path="/commercialjet"
          exact
          element={
            <>
              <Header handleOpen={handleOpen} inquiry={enquiry} />
              <Sidebar handleClose={handleClose} setX={setX} x={x} />
              <Commericaljetpage />
              <Footer />
            </>
          }
        />

        <Route
          path="/privatejet"
          exact
          element={
            <>
              <Header handleOpen={handleOpen} inquiry={enquiry} />
              <Sidebar handleClose={handleClose} setX={setX} x={x} />
              <Privatejetpage />
              <Footer />
            </>
          }
        />

        <Route
          path="/helicopter"
          exact
          element={
            <>
              <Header handleOpen={handleOpen} inquiry={enquiry} />
              <Sidebar handleClose={handleClose} setX={setX} x={x} />
              <Helicopterpage />
              <Footer />
            </>
          }
        />

        <Route
          path="/aviation"
          exact
          element={
            <>
              <Header handleOpen={handleOpen} inquiry={enquiry} />
              <Sidebar handleClose={handleClose} setX={setX} x={x} />
              <AviationFuel />
              <Footer />
            </>
          }
        />
        <Route
          path="/jetdetails"
          exact
          element={
            <>
              <Header handleOpen={handleOpen} inquiry={enquiry} />
              <Sidebar handleClose={handleClose} setX={setX} x={x} />
              <JetDetails />
              <Footer />
            </>
          }
        />
        <Route
          path="/g700"
          exact
          element={
            <>
              <Header handleOpen={handleOpen} inquiry={enquiry} />
              <Sidebar handleClose={handleClose} setX={setX} x={x} />
              <G700 />
              <Footer />
            </>
          }
        />
        <Route
          path="/buy"
          exact
          element={
            <>
              <BuyForm />
            </>
          }
        />
        <Route
          path="/sell"
          exact
          element={
            <>
              <SellForm />
            </>
          }
        />
        <Route
          path="/tour-form"
          exact
          element={
            <>
              <TourInquiryForms />
            </>
          }
        />
        <Route
          path="/otp"
          exact
          element={
            <>
              <OtpPage />
            </>
          }
        />
        <Route
          path="/thanku"
          exact
          element={
            <>
              <Thankupage />
            </>
          }
        />
        <Route
          path="/AboutUs"
          exact
          element={
            <>
              <Header handleOpen={handleOpen} inquiry={enquiry} />
              <Sidebar handleClose={handleClose} setX={setX} x={x} />
              <AboutUs />
              <Footer />
            </>
          }
        />
        <Route
          path="/client-jet"
          exact
          element={
            <>
              <Header handleOpen={handleOpen} inquiry={sellEnquiry} />
              <Sidebar handleClose={handleClose} setX={setX} x={x} />
              <ClinetJet />
              <Footer />
            </>
          }
        />
        <Route
          path="/client-jet-details/:id"
          exact
          element={
            <>
              <Header handleOpen={handleOpen} inquiry={sellEnquiry} />
              <Sidebar handleClose={handleClose} setX={setX} x={x} />
              <ClientJetDetails />
              <Footer />
            </>
          }
        />
        <Route
          path="/Tour"
          exact
          element={
            <>
              <Header handleOpen={handleOpen} inquiry={tourEnquiry} />
              <Sidebar handleClose={handleClose} setX={setX} x={x} />
              <Tour />
              <Footer />
            </>
          }
        />
        <Route
          path="/Tour-details/:id"
          exact
          element={
            <>
              <Header handleOpen={handleOpen} inquiry={tourEnquiry} />
              <Sidebar handleClose={handleClose} setX={setX} x={x} />
              <TourDeatils />
              <Footer />
            </>
          }
        />
        <Route
          path="/Tour-images/:id"
          exact
          element={
            <>
              <Header handleOpen={handleOpen} inquiry={tourEnquiry} />
              <Sidebar handleClose={handleClose} setX={setX} x={x} />
              <Tour_img />
              <Footer />
            </>
          }
        />
        <Route
          path="/inquiry"
          exact
          element={
            <>
              <Contactus />
            </>
          }
        />
        <Route
          path="/Term-and-conditions"
          exact
          element={
            <>
              <Header handleOpen={handleOpen} inquiry={enquiry} />
              <Sidebar handleClose={handleClose} setX={setX} x={x} />
              <Term_and_condition />
              <Footer />
            </>
          }
        />
        <Route
          path="/PRIVACY_POLICY"
          exact
          element={
            <>
              <Header handleOpen={handleOpen} inquiry={enquiry} />
              <Sidebar handleClose={handleClose} setX={setX} x={x} />
              <Privacy_policy />
              <Footer />
            </>
          }
        />
        <Route
          path="/faq"
          exact
          element={
            <>
              <Header handleOpen={handleOpen} inquiry={enquiry} />
              <Sidebar handleClose={handleClose} setX={setX} x={x} />
              <Faqbar />
              <Footer />
            </>
          }
        />
        <Route
          path="/unlocking-possibilities/:name"
          exact
          element={
            <>
              <Header handleOpen={handleOpen} inquiry={enquiry} />
              <Sidebar handleClose={handleClose} setX={setX} x={x} />
              <UnlockingDetails />
              <Footer />
            </>
          }
        />
      </Routes>
    </>
  );
}

export default App;
