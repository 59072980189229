import React from "react";
import "./unlocking.css";
import { useNavigate } from "react-router-dom";

function Unlocking() {
  const navigate = useNavigate();
  const Data = [
    {
      image: require("../../assets/images/unlocking/1.png"),
      name: "Sale and acquisition of jets.",
      desc: `<p>Unveil the world of luxury air travel with our comprehensive private jet sales and acquisition services. Discover seamless solutions tailored to your requests, whether you're looking to purchase your dream private jet or optimize your private jet fleet. From consultation to delivery, we will pilot you through every step of the process, ensuring a smooth and satisfying experience. Elevate your journey with us Seven Air Aviation today.
      </p>`,
      img: [
        require("../../assets/images/unlocking/unlocking/sales/img1.png"),
        require("../../assets/images/unlocking/unlocking/sales/img2.png"),
        require("../../assets/images/unlocking/unlocking/sales/img3.png"),
        require("../../assets/images/unlocking/unlocking/sales/img4.png"),
      ],
      pdf: null,
    },
    {
      image: require("../../assets/images/unlocking/2.png"),
      name: "Private jet ownership Consultation",
      desc: `
    <p><b>Your Path to Exclusive Travel </b>
    Embark on the journey to private jet ownership with confidence and clarity through our expert consultation services. Explore the benefits and intricacies of private jet ownership tailored to your needs. From financial considerations to aircraft selection, our seasoned professionals guide you through every step, ensuring a seamless transition to exclusive air travel. Experience the ultimate in luxury and convenience as you soar to new heights with Seven Air Aviation.</p>
  `,
      img: [
        require("../../assets/images/unlocking/unlocking/private/img1.png"),
        require("../../assets/images/unlocking/unlocking/private/img2.png"),
        require("../../assets/images/unlocking/unlocking/private/img3.png"),
        require("../../assets/images/unlocking/unlocking/private/img4.png"),
      ],
      pdf: null,
    },
    {
      image: require("../../assets/images/unlocking/3.png"),
      name: "Evaluation and appraisal",
      desc: `
      <p><b>Evaluation & Appraisal: Maximizing Your Private Jet Investment;</b> Discover the true value of your private jet with our comprehensive evaluation and appraisal services. Our expert team utilizes industry-leading methodologies to assess the condition, performance, and market value of your aircraft accurately. Whether you're considering a sale, acquisition, or simply seeking to optimize your asset portfolio, trust us to provide transparent and insightful appraisal services tailored to your needs. Make informed decisions and maximize the return on your private jet investment right here with Seven Air Aviation.</p>
      `,
      img: [
        require("../../assets/images/unlocking/unlocking/evaluation/img1.png"),
        require("../../assets/images/unlocking/unlocking/evaluation/img2.png"),
        require("../../assets/images/unlocking/unlocking/evaluation/img3.png"),
        require("../../assets/images/unlocking/unlocking/evaluation/img4.png"),
      ],
      pdf: null,
    },
    {
      image: require("../../assets/images/unlocking/4.png"),
      name: "Financing and leasing options",
      desc: `
      <p><b>Tailored Solutions for Private Jet Acquisition</b>	Explore flexible financing and leasing options designed to facilitate your private jet acquisition journey. Here at Seven Air our expert team works closely with reputable financial institutions to offer personalized solutions that align with your financial goals and preferences. Whether you're looking to secure financing for a new acquisition or exploring leasing options for greater flexibility or even for private and commercial fleets we provide transparent guidance and support every step of the way. Experience the convenience and peace of mind of tailored financing solutions with us by beginning to complete or prepare with the Seven Air Financing checklist attached.</p>
      `,
      img: [
        require("../../assets/images/unlocking/unlocking/finance/img1.png"),
        require("../../assets/images/unlocking/unlocking/finance/img2.png"),
        require("../../assets/images/unlocking/unlocking/finance/img3.png"),
        require("../../assets/images/unlocking/unlocking/finance/img4.png"),
      ],
      pdf: "https://sevenairimage.s3.us-east-2.amazonaws.com/Aircraft%20Financing%20Checklist-%20Seven%20Air%201.pdf",
    },
    {
      image: require("../../assets/images/unlocking/5.png"),
      name: "Customer support & Yachts Sale",
      desc: `<p><b>Customer Support & Yacht Sales</b> Experience unparalleled customer support as you explore our range of luxury private jets and yacht sales. Our dedicated team is committed to providing personalized assistance at every stage of your journey, from initial inquiry to post-sale support. Whether you're purchasing your first private jet or seeking the perfect yacht for your next adventure, we're here to exceed your expectations. Discover excellence in service and luxury in every detail with us.</p>`,
      img: [
        require("../../assets/images/unlocking/unlocking/customers/img4.png"),
        require("../../assets/images/unlocking/unlocking/customers/img3.png"),
        require("../../assets/images/unlocking/unlocking/customers/img1.png"),
        require("../../assets/images/unlocking/unlocking/customers/img2.png"),
      ],
      pdf: null,
    },
    {
      image: require("../../assets/images/unlocking/6.png"),
      name: "Pilot Training",
      desc: `<p><b>Elevating Your Skills in Private Jet Aviation </b> Also here at Seven Air Aviation you can Unlock your potential as a skilled aviator with our comprehensive pilot training program. Tailored specifically for private jet operations, our training curriculum covers everything from fundamental flight principles to advanced avionics systems. Led by experienced instructors and conducted in state-of-the-art facilities, our program ensures that you are equipped with the knowledge and skills needed to navigate the skies confidently and safely. Elevate your career and enhance your capabilities with our premium pilot training services.</p>`,
      img: [
        require("../../assets/images/unlocking/unlocking/pilot/img1.png"),
        require("../../assets/images/unlocking/unlocking/pilot/img2.png"),
        require("../../assets/images/unlocking/unlocking/pilot/img3.png"),
        require("../../assets/images/unlocking/unlocking/pilot/img4.png"),
      ],
      pdf: null,
    },
    {
      image: require("../../assets/images/unlocking/7.png"),
      name: "Corporate jet fleet acquisition",
      desc: `<p><b>Streamlined Solutions for Business Aviation</b> Discover seamless acquisition services specifically designed by here at home Seven Air Aviation for corporate jet fleets. Whether you're expanding your company's aviation capabilities or seeking to optimize your existing fleet, our expert team provides comprehensive solutions designed to meet your business objectives. From consultation to delivery, we offer personalized guidance and support, ensuring a smooth and efficient acquisition process. Elevate your business aviation experience with our trusted corporate jet fleet acquisition strategies and services.</p>`,
      img: [
        require("../../assets/images/unlocking/unlocking/coperate/img1.png"),
        require("../../assets/images/unlocking/unlocking/coperate/img2.png"),
        require("../../assets/images/unlocking/unlocking/coperate/img3.png"),
        require("../../assets/images/unlocking/unlocking/coperate/img4.png"),
      ],
      pdf: null,
    },
    {
      image: require("../../assets/images/unlocking/8.png"),
      name: "National Air Assets Acquisition Assistance",
      desc: `<p><b>Elevating Aviation Investment Opportunities</b> Explore exclusive opportunities in national airline assets acquisition through our specialized services. Our expert team navigates the complexities of national airline acquisitions, providing strategic guidance and meticulous evaluation to maximize a nation’s investment potential or simply expanding on the nation’s fleet. Whether you're looking to expand your portfolio or capitalize on emerging market opportunities, trust us to facilitate seamless transactions and deliver unparalleled results. Elevate your aviation investment strategy with our dedicated national airline assets and acquisition services.</p>`,
      img: [
        require("../../assets/images/unlocking/unlocking/national/img1.png"),
        require("../../assets/images/unlocking/unlocking/national/img2.png"),
        require("../../assets/images/unlocking/unlocking/national/img3.png"),
        require("../../assets/images/unlocking/unlocking/national/img4.png"),
      ],
      pdf: null,
    },
  ];

  return (
    <section className="unlocking">
      <div>
        <div className="body-section">
          <div className="heading">
            <h2>Unlocking Possibilities</h2>
            <p>Our Diverse Range of Services</p>
          </div>
          <div className="flex cardbody flex-wrap justify-center flex-row w-full">
            {Data.map((item, index) => (
              <div
                key={index}
                className="cardcont lg:basis-1/4 md:basis-1/3 basis-1/2"
                onClick={() =>
                  navigate(`/unlocking-possibilities/${item?.name}`, {
                    state: item,
                  })
                }
              >
                <div className="card">
                  <div className="flex justify-center">
                    <img src={item.image} alt="" />
                  </div>
                  <h4 className="px-5">{item.name}</h4>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
}

export default Unlocking;
