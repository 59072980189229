import React from "react";
import plane from "../../assets/images/facts/plane.png";
import Img1 from "../../assets/images/facts/p1.png";
import Img2 from "../../assets/images/facts/p2.png";
import Img3 from "../../assets/images/facts/p3.png";
import "./fact.css";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "swiper/css";
import {
  Autoplay,
  Navigation,
  Pagination,
  Mousewheel,
  Keyboard,
} from "swiper/modules";

function Facts() {
  const settings1 = {
    infinite: true,
    loop: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false,
    arrows: true,
    pauseOnHover: false,
    vertical: false,
  };

  return (
    <>
      <section className="facts">
        <div>
          <div className=" flex flex-wrap justify-center w-full">
            <div className="lg:basis-2/5">
              <div className="w-100">
                <div className="heading text-left">
                  <h2 className="text-left">Unveiling the Luxurious Skies </h2>
                  <p className="pt-4 text-center">
                    Fascinating Facts About Private Jets
                  </p>
                </div>
              </div>
            </div>
            <div className="lg:basis-3/5 relative z-10">
              <div className="">
                <img src={plane} alt="" className="w-100" />
              </div>
              <div className="absolute facttitle">
                <h4>facts</h4>
              </div>
            </div>
          </div>
          <div className="flex  sides">
            <div className="side1">
              <Swiper
                className="mySwiper h-full slider1"
                spaceBetween={0}
                centeredSlides={true}
                autoplay={{
                  delay: 3000,
                  disableOnInteraction: false,
                }}
                pagination={{
                  clickable: true,
                }}
                loop
                navigation={false}
                modules={[Pagination, Autoplay]}
                slidesPerView={"auto"}
              >
                <SwiperSlide>
                  <img src={Img1} alt="" />
                </SwiperSlide>
                <SwiperSlide>
                  <img src={Img2} alt="" />
                </SwiperSlide>
                <SwiperSlide>
                  <img src={Img3} alt="" />
                </SwiperSlide>
              </Swiper>
            </div>
            <div className="side2">
              <Swiper
                className="mySwiper slider2"
                spaceBetween={0}
                centeredSlides={true}
                loop
                cssMode={true}
                navigation={true}
                pagination={false}
                mousewheel={true}
                keyboard={true}
                modules={[Navigation, Pagination, Mousewheel, Keyboard]}
              >
                <SwiperSlide className="items">
                  <h2>
                    Half the Private Jets in the World are owned by Americans
                  </h2>
                  <p>
                    Americans own a substantial share of the world's private
                    jets, with nearly half of these high-end aircraft registered
                    in the United States. This dominance underscores the
                    country's robust economy, a strong culture of business
                    aviation, and the widespread use of private jets for both
                    business and personal travel. The significant ownership
                    presence also reflects the vast geographic expanse of the
                    United States, emphasizing the practicality and efficiency
                    of private aviation for covering large distances.
                  </p>
                </SwiperSlide>
                <SwiperSlide className="items">
                  <h2>
                    The US Super Bowl is the Event Most Traveled To by Private
                    Jet
                  </h2>
                  <p>
                    The US Super Bowl stands out as the most private
                    jet-traveled event, attracting a surge of high-profile
                    attendees. Renowned for its star-studded audience and
                    extravagant parties, the Super Bowl draws celebrities,
                    executives, and VIPs who prefer the luxury and convenience
                    of private aviation. The demand for private jets during this
                    spectacle underscores the event's status as a premier
                    destination, with individuals opting for swift, exclusive
                    travel to witness the sporting extravaganza in unparalleled
                    style.
                  </p>
                </SwiperSlide>
                <SwiperSlide className="items">
                  <h2>Private Jets Save Lives.</h2>
                  <p>
                    Private jets play a crucial role in emergency medical
                    evacuations, swiftly transporting critically ill patients to
                    specialized medical facilities. These nimble aircraft can
                    access remote locations, reducing transit time and
                    increasing the chances of survival. Medical evacuation via
                    private jets is not only faster but also allows for
                    specialized medical equipment and personnel on board,
                    ensuring timely and advanced care. In disaster-stricken
                    areas or regions with limited infrastructure, private jets
                    serve as lifelines, facilitating the rapid transport of
                    medical supplies, personnel, and aid, ultimately
                    contributing to saving lives in critical situations.
                  </p>
                </SwiperSlide>
              </Swiper>
              {/* <Slider {...settings1} className="slider2">
                <div className="items">
                  <h2>
                    Half the Private Jets in the World are owned by Americans
                  </h2>
                  <p>
                    Americans own a substantial share of the world's private
                    jets, with nearly half of these high-end aircraft registered
                    in the United States. This dominance underscores the
                    country's robust economy, a strong culture of business
                    aviation, and the widespread use of private jets for both
                    business and personal travel. The significant ownership
                    presence also reflects the vast geographic expanse of the
                    United States, emphasizing the practicality and efficiency
                    of private aviation for covering large distances.
                  </p>
                </div>
                <div className="items">
                  <h2>
                    The US Super Bowl is the Event Most Traveled To by Private
                    Jet
                  </h2>
                  <p>
                    The US Super Bowl stands out as the most private
                    jet-traveled event, attracting a surge of high-profile
                    attendees. Renowned for its star-studded audience and
                    extravagant parties, the Super Bowl draws celebrities,
                    executives, and VIPs who prefer the luxury and convenience
                    of private aviation. The demand for private jets during this
                    spectacle underscores the event's status as a premier
                    destination, with individuals opting for swift, exclusive
                    travel to witness the sporting extravaganza in unparalleled
                    style.
                  </p>
                </div>
                <div className="items">
                  <h2>Private Jets Save Lives.</h2>
                  <p>
                    Private jets play a crucial role in emergency medical
                    evacuations, swiftly transporting critically ill patients to
                    specialized medical facilities. These nimble aircraft can
                    access remote locations, reducing transit time and
                    increasing the chances of survival. Medical evacuation via
                    private jets is not only faster but also allows for
                    specialized medical equipment and personnel on board,
                    ensuring timely and advanced care. In disaster-stricken
                    areas or regions with limited infrastructure, private jets
                    serve as lifelines, facilitating the rapid transport of
                    medical supplies, personnel, and aid, ultimately
                    contributing to saving lives in critical situations.
                  </p>
                </div>
              </Slider> */}
              x
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Facts;
