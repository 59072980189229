import React from "react";
import "./style.css";
import logo from "../../assets/images/logo.png";
import twitter from "../../assets/images/icon/ftwitter.svg";
import insta from "../../assets/images/icon/finsta.svg";
import fb from "../../assets/images/icon/ffb.svg";
import linkdin from "../../assets/images/icon/flink.svg";
import { useNavigate, Link } from "react-router-dom";
function Footer() {
  const navigate = useNavigate();
  return (
    <>
      <footer>
        <div className="cont">
          <div className="log mb-4">
            <img src={logo} alt="img" onClick={() => navigate("/")} />
          </div>
          <div className="md:flex flex-wrap justify-between ">
            <div>
              <h2>Seven air Aviation</h2>
              <p className="pt-6 pb-6">
                2001 L Place Northwest Suite 500, <br /> Northwest Washington,
                Washington, DC, USA
              </p>
            </div>
            <div>
              <ul>
                <li onClick={() => navigate("/AboutUs")}>About Us</li>
                <li onClick={() => navigate("/Term-and-conditions")}>
                  Terms & Conditions
                </li>
                <li onClick={() => navigate("/PRIVACY_POLICY")}>
                  Privacy policy
                </li>
                <li onClick={() => navigate("/faq")}>FAQ</li>
              </ul>
            </div>
            <div>
              <ul>
                <li onClick={() => navigate("/buy")}>Buy</li>
                <li onClick={() => navigate("/sell")}>Sell</li>
                <li onClick={() => navigate("/tour-form")}>Tour</li>
              </ul>
            </div>
            <div>
              <p>Connect With Us</p>
              <div className="flex mt-5">
                <Link to="https://twitter.com/Teamsevenair" target="_blank">
                  <img src={twitter} alt="" className="mr-5" />
                </Link>
                <Link
                  to="https://www.instagram.com/sevenairaviation/"
                  target="_blank"
                >
                  <img src={insta} alt="" className="mr-5" />
                </Link>
                <Link
                  to="https://www.facebook.com/sevenairaviation"
                  target="_blank"
                >
                  <img src={fb} alt="" className="mr-5" />
                </Link>
                <img src={linkdin} alt="" className="mr-5" />
              </div>
            </div>
          </div>
        </div>
        <div className="foot">
          <p>©2024 Seven Air Aviation. All right reserved.</p>
        </div>
      </footer>
    </>
  );
}

export default Footer;
