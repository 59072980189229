import React, { useEffect, useState } from "react";
import axios from "../../utils/axios";
import "./style.css";
import Overlay from "../../component/overlay";
function Privacy_policy() {
  const [isLoading, setIsLoading] = useState(false);

  const [data, setData] = useState();
  const handleData = async () => {
    setIsLoading(true);
    try {
      const data = await axios.get("/getContent/PRIVACY_POLICY");
      setData(data);
      console.log(data);
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);

      console.log(err);
    }
  };
  useEffect(() => {
    handleData();
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <section className="privacy">
        <div className="heading mb-8">
          <h2>PRIVACY POLICY</h2>
        </div>
        <div dangerouslySetInnerHTML={{ __html: data?.data?.data?.content }} />

        {/* <div>{data?.data?.data?.content}</div> */}
      </section>
      {isLoading && <Overlay />}
    </>
  );
}

export default Privacy_policy;
